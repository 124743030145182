import { Row, Col } from "react-bootstrap";
import "./styles/orderpage.css";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { GET_SINGLE_ORDER_DETAIL } from "../graphql/quries";
import {
  UPDATE_PARTNER,
  ADD_DRIVER,
  ADD_TRUCK,
  UPDATE_ORDER_STATUS,
} from "../graphql/mutation";
import { nairalize } from "../utils/moneyfy";

function DetailOrderPage() {
  let { id } = useParams();

  const [partnerData, setPartnerData] = useState("");
  const [driverID, setDriverID] = useState("");
  const [truckID, setTruckID] = useState("");
  const [AgreedPrice, setAgreedPrice] = useState(0);
  const { loading, error, data } = useQuery(GET_SINGLE_ORDER_DETAIL(id));

  useEffect(() => {
    async function a() {
      let detailItem2 = await data.order[0];
      setPartnerData(detailItem2.partnerId);
      setDriverID(detailItem2.driverId);
      setTruckID(detailItem2.truckId);
      setAgreedPrice(detailItem2.agreedPrice);
    }
    if (data !== undefined) {
      a();
    }
  }, [data]);
  const [updatePartn] = useMutation(UPDATE_PARTNER);
  const [AddNewDriver] = useMutation(ADD_DRIVER);
  const [AddNewTruck] = useMutation(ADD_TRUCK);
  const [UpdateStatus] = useMutation(UPDATE_ORDER_STATUS);

  const PartnerID = useRef() as React.MutableRefObject<HTMLSelectElement>;
  const driverFirstName = useRef() as React.MutableRefObject<HTMLInputElement>;
  const driverLastName = useRef() as React.MutableRefObject<HTMLInputElement>;
  const driverPhoneNumber =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const DriverLicenceNumber =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const truckPlateNumber = useRef() as React.MutableRefObject<HTMLInputElement>;
  const truckChasisNumber =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const truckColor = useRef() as React.MutableRefObject<HTMLInputElement>;
  const truckDescription = useRef() as React.MutableRefObject<HTMLInputElement>;
  const truckModel = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [openDriver, setOpenDriver] = useState(false);
  const [openTruck, setOpenTruck] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);

  // Methods to Open & Close the Truck, Partner Dialog
  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleToggleLoader = () => {
    setOpenLoader(!openLoader);
  };

  const handleClickOpen = () => {
    setOpenDriver(true);
  };

  const handleClose = () => {
    setOpenDriver(false);
  };
  const handleClickOpen2 = () => {
    setOpenTruck(true);
  };

  const handleClose2 = () => {
    setOpenTruck(false);
  };

  if (loading) {
    return (
      <div className="DetailOrderPage-MainContainer">
        <h3>Order No: #{id}</h3>
        <div>Loading...</div>
      </div>
    );
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  let detailItem = data.order[0];
  let partnersName = data.partner;
  let driverData = data.driver;
  let truckData = data.truck;

  async function updatePartner() {
    await updatePartn({
      variables: {
        id: id,
        partnerID: partnerData,
        AgreedValue: AgreedPrice,
        driverId: driverID,
        truckId: truckID,
      },
    });
    window.history.back();
  }
  async function updateStatusSubmit() {
    try {
      handleToggleLoader();
      await UpdateStatus({
        variables: {
          id: id,
        },
      });
      window.location.reload();
    } catch (e) {}
    handleCloseLoader();
  }
  async function AddNewDriverSubmit(e: any) {
    e.preventDefault();
    await AddNewDriver({
      variables: {
        driverLicenseNumber: DriverLicenceNumber.current.value,
        driverFirstName: driverFirstName.current.value,
        driverLastName: driverLastName.current.value,
        driverPhoneNumber: driverPhoneNumber.current.value,
      },
    });
    window.location.reload();
  }

  async function AddNewTruckSubmit(e: any) {
    e.preventDefault();
    await AddNewTruck({
      variables: {
        truckChasisNumber: truckChasisNumber.current.value,
        truckDescription: truckDescription.current.value,
        truckColor: truckColor.current.value,
        truckPlateNumber: truckPlateNumber.current.value,
        truckModel: truckModel.current.value,
      },
    });
    window.location.reload();
  }
  return (
    <div className="DetailOrderPage-MainContainer">
      <Row>
        <Col lg={2} md={4} sm={12}>
          <h3>
            <b>Order Id: {id}</b>
          </h3>
        </Col>
        <Col lg={6} md={4} sm={0}></Col>
        <Col lg={4} md={4} sm={12}>
          {detailItem.status === "pending" ? (
            <>
              <button onClick={() => updateStatusSubmit()}>Cancle Order</button>
              <button>Edit</button>
            </>
          ) : (
            <>
              <button className="disableBtn">Cancle Order</button>
              <button className="disableBtn">Edit</button>
            </>
          )}
        </Col>
      </Row>

      <Row className="OrderDetailRow">
        <Col lg={6} md={6} sm={12}>
          <b>Customer Id:</b>
          <p>#{detailItem.customerId}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          {detailItem.customer.customerType === "corporate" ? (
            <>
              <b>Company Name:</b>
              <p>{detailItem.customer.companyName}</p>
            </>
          ) : (
            <></>
          )}
          {detailItem.customer.customerType === "individual" ? (
            <>
              <b>Customer Name:</b>
              <p>{`${detailItem.customer.firstName} ${detailItem.customer.lastName}`}</p>
            </>
          ) : (
            <></>
          )}
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Status</b>
          <p>{detailItem.status}</p>
        </Col>
        <Col lg={12} md={12} sm={12} className="OrderDetailRow orderDetailRec">
          <h4>
            <b>Order Details</b>
          </h4>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Weight:</b>
          <p>{detailItem.weight}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Price</b>
          <p>{nairalize(detailItem.price)}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Package Type</b>
          <p>{detailItem.packageType}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Item Type</b>
          <p>{detailItem.itemType}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Paid Amount</b>
          <p>{detailItem.totalPayedAmount}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Product Secound Part Amount</b>
          <p>{detailItem.firstPartPayed}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>First Part Payment</b>
          <p>{detailItem.secondPartPaymentAmount}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Secound Part Payment</b>
          <p>{detailItem.secondPartPaymentAmount}</p>
        </Col>
        <Col lg={12} md={12} sm={12} className="OrderDetailRow orderDetailRec">
          <h4>
            <b>PickUp Details</b>
          </h4>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Pickup Full Name</b>
          <p>{detailItem.pickupFullname}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Pickup Phone Number</b>
          <p>{detailItem.pickupPhoneNumber}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Pickup Address</b>
          <p>{detailItem.pickUpAddress}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Pickup At</b>
          <p>{detailItem.pickedUpAt}</p>
        </Col>
        <Col lg={12} md={12} sm={12} className="OrderDetailRow orderDetailRec">
          <h4>
            <b>Delivery Details:</b>
          </h4>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Delivery Full Name</b>
          <p>{detailItem.deliveryFullname}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Delivery Phone Number</b>
          <p>{detailItem.deliveryPhoneNumber}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Delivery State</b>
          <p>{detailItem.deliveryState}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Delivery Address</b>
          <p>{detailItem.deliveryAddress}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Delivery Code</b>
          <p>{detailItem.deliveryCode}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Delivery At</b>
          <p>{detailItem.deliveredAt}</p>
        </Col>
        <Col lg={12} md={12} sm={12} className="OrderDetailRow orderDetailRec">
          <h4>
            <b>Extra:</b>
          </h4>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Drop Off Instruction</b>
          <p>{detailItem.dropOffInstruction}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Extra Details</b>
          <p>{detailItem.extraDetails}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Created At</b>
          <p>{detailItem.createdAt}</p>
        </Col>
      </Row>
      {detailItem.status === "pending" ? (
        <Row className="OrderDetailRow">
          <h3>
            <b>Assign Partner:</b>
          </h3>
          <Col lg={12} md={12} sm={12}>
            <select
              onChange={(e) => setPartnerData(e.target.value)}
              className="SelectOptMenu"
              defaultValue={detailItem.partnerId}
            >
              <option value="">Select Partner</option>
              {partnersName.map((a: any) => {
                return <option value={a.id}>{a.fullName}</option>;
              })}
            </select>
          </Col>

          <Col lg={12} md={12} sm={12}>
            <h3>
              <b>Assign Agreed Price:</b>
            </h3>
            <input
              type="number"
              onChange={(e) => setAgreedPrice(parseInt(e.target.value))}
              defaultValue={detailItem.agreedPrice}
              className="SetPrice"
              placeholder="Agreed Price"
            />
          </Col>
          <Col lg={4} md={12} sm={12}>
            <h4>
              <b>Assign Driver and Truck:</b>
            </h4>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <button onClick={handleClickOpen}>Add New Driver</button>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <button onClick={handleClickOpen2}>Add New Truck</button>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <select
              onChange={(e) => setDriverID(e.target.value)}
              className="SelectOptMenu"
              defaultValue={detailItem.driverId}
            >
              <option value="">Select Driver</option>
              {driverData.map((a: any) => {
                return (
                  <option value={a.id}>{`${a.firstName} ${a.lastName}`}</option>
                );
              })}
            </select>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <select
              onChange={(e) => setTruckID(e.target.value)}
              className="SelectOptMenu"
              defaultValue={detailItem.truckId}
            >
              <option value="">Select Truck</option>
              {truckData.map((truck: any) => {
                return (
                  <option
                    value={truck.id}
                  >{`Plate Number: ${truck.plateNumber}`}</option>
                );
              })}
            </select>
          </Col>
          {partnerData !== null &&
          truckID !== null &&
          driverID !== null &&
          AgreedPrice !== null ? (
            <button onClick={() => updatePartner()}>Save</button>
          ) : (
            <button disabled className="disableBtn">
              Save
            </button>
          )}
          
          {/* Processing Loader */}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoader}
            onClick={handleCloseLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Row>
      ) : (
        <></>
      )}

      {/* Dialog for Creating new Driver */}
      <Dialog open={openDriver} onClose={handleClose}>
        <div className="driverTruckPopup">
          <h4>Add Driver</h4>
          <form onSubmit={(e) => AddNewDriverSubmit(e)}>
            <Row>
              <Col lg={6} md={12} sm={12}>
                <input placeholder="First Name" ref={driverFirstName} />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input placeholder="Last Name" ref={driverLastName} />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input placeholder="Phone Number" ref={driverPhoneNumber} />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input
                  placeholder="Driver License Number"
                  ref={DriverLicenceNumber}
                />
              </Col>
              <Col lg={12} md={12} sm={12} className="driverTruckBtnAlignment">
                <button type="submit">Add</button>
              </Col>
            </Row>
          </form>
        </div>
      </Dialog>
      
      {/* Dialog for Creating new Truck */}
      <Dialog open={openTruck} onClose={handleClose2}>
        <div className="driverTruckPopup">
          <h4>Add Truck</h4>
          <form onSubmit={(e) => AddNewTruckSubmit(e)}>
            <Row>
              <Col lg={6} md={12} sm={12}>
                <input placeholder="Plate Number" ref={truckPlateNumber} />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input placeholder="Chasis Number" ref={truckChasisNumber} />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input placeholder="Color" ref={truckColor} />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input placeholder="Description" ref={truckDescription} />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input placeholder="Truck Model" ref={truckModel} />
              </Col>
              <Col lg={12} md={12} sm={12} className="driverTruckBtnAlignment">
                <button>Add</button>
              </Col>
            </Row>
          </form>
        </div>
      </Dialog>
    </div>
  );
}

export default DetailOrderPage;
