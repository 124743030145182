import { Row, Col } from "react-bootstrap";
import "./styles/orderpage.css";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_CUSTOMERS_DETAIL } from "../graphql/quries";

function CustomerDetailPage() {
  let { id } = useParams();
  const { loading, error, data } = useQuery(GET_CUSTOMERS_DETAIL(id));
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  let detailItem = data.customer[0];
  return (
    <div className="DetailOrderPage-MainContainer">
      <h3>
        <b>Customer Id: {id}</b>
      </h3>
      <Row className="OrderDetailRow">
        <Col lg={6} md={6} sm={12}>
          <b>Can Post Orders</b>
          <p>#{detailItem.canPostOrders}</p>
        </Col>
        <Col lg={12} md={12} sm={12} className="OrderDetailRow orderDetailRec">
          <h4>
            <b>Customer Details</b>
          </h4>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>First Name:</b>
          <p>{detailItem.firstName}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Last Name</b>
          <p>{detailItem.lastName}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Email</b>
          <p>{detailItem.email}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Company Name</b>
          <p>{detailItem.companyName}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Mobile</b>
          <p>{detailItem.mobile}</p>
        </Col>
        <Col lg={12} md={12} sm={12} className="OrderDetailRow orderDetailRec">
          <h4>
            <b>Other Details</b>
          </h4>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Ensturance Enabled</b>
          <p>{detailItem.insuranceEnabled}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Payment Method</b>
          <p>{detailItem.paymentMethod}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Pickup Address</b>
          <p>{detailItem.pickUpAddress}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Service Charge Amount</b>
          <p>{detailItem.serviceChargeAmount}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Service Charge Enabled</b>
          <p>{detailItem.serviceChargeEnabled}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>RC Type</b>
          <p>{detailItem.rcType}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>RC Number</b>
          <p>{detailItem.rcNum}</p>
        </Col>
      </Row>
    </div>
  );
}

export default CustomerDetailPage;
