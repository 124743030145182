import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import "./styles/orderpage.css";
import { useQuery, useMutation } from "@apollo/client";
import SingleCustomerRow from "../Components/customers/singleCustomerRow";
import ReactPaginate from "react-paginate";
import { GET_CUSTOMERS_LIST } from "../graphql/quries";
import { CreateNewCustomer } from "../graphql/mutation";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function CustomersPage() {
  const { loading, error, data } = useQuery(GET_CUSTOMERS_LIST);
  const [currentCustomer, setCurrentCustomer] = useState("individual");
  const [pageNumber, setPageNumber] = useState(0);
  const [openNewCustomer, setOpenNewCustomer] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const CustomerFirstName =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const CustomerLastName = useRef() as React.MutableRefObject<HTMLInputElement>;
  const CustomerEmail = useRef() as React.MutableRefObject<HTMLInputElement>;
  const CustomerCustomerType =
    useRef() as React.MutableRefObject<HTMLSelectElement>;
  const CustomerCompanyName =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const CustomerCompanyAddress =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const CustomerMobile = useRef() as React.MutableRefObject<HTMLInputElement>;
  const CustomerPaymentMethod =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const CustomerRCType = useRef() as React.MutableRefObject<HTMLInputElement>;
  const CustomerRCNumber = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [creatNewCust] = useMutation(CreateNewCustomer);

  async function CreateNewCustomerHandle(e: any) {
    e.preventDefault();
    await creatNewCust({
      variables: {
        companyAddress: CustomerCompanyAddress.current.value,
        companyName: CustomerCompanyName.current.value,
        customerType: CustomerCustomerType.current.value?.toLowerCase(),
        email: CustomerEmail.current.value,
        firstName: CustomerFirstName.current.value,
        lastName: CustomerLastName.current.value,
        rcType: CustomerRCType.current.value,
        rcNum: CustomerRCNumber.current.value,
        paymentMethod: CustomerPaymentMethod.current.value,
        mobile: CustomerMobile.current.value,
      },
    });
    handleCloseLoader();
    window.location.reload();
    setCurrentCustomer("corporate");
  }

  // Methods of Open & Close the Dialogs
  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleToggleLoader = () => {
    setOpenLoader(!openLoader);
  };
  const CustomerHandleClick = () => {
    setOpenNewCustomer(true);
  };
  const CustomerHandleClose = () => {
    setOpenNewCustomer(false);
  };

  if (loading) {
    return (
      <div className="OrderPage-MainContainer">
        <h3>Customers</h3>
        <div className="OrderSection">
          <div className="OrderHeadings"></div>
          <div className="singleorder">
            <Row className="orderStatusFilter">
              <Col lg={2}>
                <button
                  className="sortFilerBtn"
                  onClick={() => {
                    setCurrentCustomer("individual");
                  }}
                >
                  Individuals
                </button>
              </Col>
              <Col lg={2}>
                <button
                  className="sortFilerBtn"
                  onClick={() => {
                    setCurrentCustomer("corporate");
                  }}
                >
                  Corporates
                </button>
              </Col>
            </Row>
            <br />
            <Row className="orderHeadingRow">
              <Col lg={3}>
                <b>FirstName</b>
              </Col>
              <Col lg={3}>
                <b>LastName</b>
              </Col>
              <Col lg={3}>
                <b>Email</b>
              </Col>
              <Col lg={3}>
                <b>Actions</b>
              </Col>
            </Row>
            <Row className="LoadingComponent">
              <Col lg={12}>
                <h4>Loading....</h4>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
  if (error || !data) {
    return <div>Error...</div>;
  }

  let MyCustomers = data.customer;

  // Code for Pagination
  const CustomerPerPage = 20;
  const PageVisited = pageNumber * CustomerPerPage;
  const DisplayPaginatedCustomerWeb = MyCustomers.slice(
    PageVisited,
    PageVisited + CustomerPerPage
  ).map((cusmer: any) => {
    return (
      <SingleCustomerRow
        data={cusmer}
        currentCustomer={currentCustomer}
        view="web"
      />
    );
  });
  const DisplayPaginatedCustomerMobile = MyCustomers.slice(
    PageVisited,
    PageVisited + CustomerPerPage
  ).map((cusmer: any) => {
    return (
      <SingleCustomerRow
        data={cusmer}
        currentCustomer={currentCustomer}
        view="mobile"
      />
    );
  });
  const PageCount = Math.ceil(MyCustomers.length / CustomerPerPage);
  const ChangePage = ({ selected }: any) => {
    setPageNumber(selected);
  };

  return (
    <div className="OrderPage-MainContainer">
      <Row>
        <Col lg={6} md={6} sm={6} xs={12}>
          <h3>Customers</h3>
        </Col>
        <Col lg={6} md={6} sm={6} xs={12}>
          <div className="NewOrderBtn_Div">
            <button
              className="orderBtn"
              onClick={() => {
                CustomerHandleClick();
              }}
            >
              New Customer
            </button>
          </div>
        </Col>
      </Row>
      <div className="OrderSection">
        <div className="OrderHeadings"></div>
        <div className="singleorder">
          <Row className="orderStatusFilter">
            <Col lg={2}>
              <button
                className="sortFilerBtn"
                onClick={() => {
                  setCurrentCustomer("individual");
                }}
              >
                Individuals
              </button>
            </Col>
            <Col lg={2}>
              <button
                className="sortFilerBtn"
                onClick={() => {
                  setCurrentCustomer("corporate");
                }}
              >
                Corporates
              </button>
            </Col>
          </Row>
          <br />
          <div className="d-none d-sm-block">
            <Row className="orderHeadingRow">
              {currentCustomer === "individual" ? (
                <>
                  <Col lg={2}>
                    <b>FirstName</b>
                  </Col>
                  <Col lg={2}>
                    <b>LastName</b>
                  </Col>
                </>
              ) : (
                <Col>
                  <b>Company Name</b>
                </Col>
              )}
              <Col lg={4}>
                <b>Email</b>
              </Col>
              <Col lg={3}>
                <b>Actions</b>
              </Col>
            </Row>
          </div>
          <div>
            <div className="d-none d-sm-block">
              {DisplayPaginatedCustomerWeb}
            </div>
            <div className=" d-sm-none d-md-block d-md-none d-lg-block d-lg-none d-xl-block d-xl-none">
              {DisplayPaginatedCustomerMobile}
            </div>
          </div>
        </div>
        <div className="OrdersPagination">
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"next"}
            pageCount={PageCount}
            onPageChange={ChangePage}
            containerClassName={"paginationBtn"}
            previousLinkClassName={"paginationPrevBtn"}
            nextLinkClassName={"paginationNextBtn"}
            disabledClassName={"paginationDisabledBtn"}
            activeClassName={"paginationActiveBtn"}
          />
        </div>
      </div>
      <Dialog open={openNewCustomer} onClose={CustomerHandleClose}>
        <div className="NewPartnerPop">
          <h4>Create New Customer</h4>
          <form
            onSubmit={(e) => {
              CreateNewCustomerHandle(e);
            }}
          >
            <Row>
              <Col lg={6} md={6} sm={12}>
                <input placeholder="First Name" ref={CustomerFirstName} />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <input placeholder="Last Name" ref={CustomerLastName} />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <input placeholder="Email" ref={CustomerEmail} />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <select ref={CustomerCustomerType}>
                  <option>Select Customer Type</option>
                  <option>Corporate</option>
                </select>
              </Col>
              <Col lg={12} md={12} sm={12}>
                <input placeholder="Company Name" ref={CustomerCompanyName} />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <input
                  placeholder="Company Address"
                  ref={CustomerCompanyAddress}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input placeholder="Mobile" ref={CustomerMobile} />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input
                  placeholder="Payment Method"
                  ref={CustomerPaymentMethod}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input placeholder="RC Type" ref={CustomerRCType} />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input placeholder="RC Number" ref={CustomerRCNumber} />
              </Col>
              <Col lg={12} md={12} sm={12} className="driverTruckBtnAlignment">
                <button
                  onClick={() => {
                    handleToggleLoader();
                  }}
                >
                  Create
                </button>
              </Col>
            </Row>
          </form>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openLoader}
          onClick={handleCloseLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
}

export default CustomersPage;
