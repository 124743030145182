import { Col, Row } from "react-bootstrap";
import "./styles/sidenavbar.css";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
type Props = {};
const SideNavBar: React.FC<Props> = ({ children }) => {
  let [userRole, setUserRol] = useState<Array<string>>([]);
  const isSuperAdmin = userRole.includes("superAdmin");
  let currentTab = useLocation();
  let addNewClass = document.getElementById(currentTab.pathname);
  if (addNewClass !== undefined) {
    addNewClass?.classList.add("activeTab");
  }
  async function GetUserRole() {
    const user = await Auth.currentAuthenticatedUser();
    let role = user.signInUserSession.accessToken.payload["cognito:groups"];
    setUserRol(role);
  }
  useEffect(() => {
    GetUserRole();
  }, []);
  async function SignOutUser() {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (err) {}
  }
  function OpenNavBar() {
    let Navbar = document.getElementById("NavBarSec");
    Navbar?.classList.remove("d-none");
    Navbar?.classList.remove("d-sm-block");
  }
  function CloseNavBar() {
    let Navbar = document.getElementById("NavBarSec");
    Navbar?.classList.add("d-none");
    Navbar?.classList.add("d-sm-block");
  }
  return (
    <div>
      <Row>
        <Col lg={2} md={2} sm={2} className="d-none d-sm-block" id="NavBarSec">
          <div className={`l-navbar show`} id="nav-bar">
            <nav className="nav">
              <button
                className="d-md-none d-lg-block d-lg-none d-xl-block d-xl-none CloseBtn"
                onClick={() => CloseNavBar()}
              >
                Close
              </button>
              <div>
                <a href="#" className="nav_logo">
                  <span className="nav_logo-name">Japtini Admin</span>
                </a>
                <div className="nav_list">
                  <a href="/" className="nav_link /dashboard" id="/">
                    <span className="nav_name">Dashboard</span>
                  </a>
                  <a href="/orders" className="nav_link /orders" id="/orders">
                    <span className="nav_name">Orders</span>
                  </a>
                  <a
                    href="/customers"
                    className="nav_link /customers"
                    id="/customers"
                  >
                    <span className="nav_name">Customers</span>
                  </a>
                  <a
                    href="/partners"
                    className="nav_link /partners"
                    id="/partners"
                  >
                    <span className="nav_name">Partners</span>
                  </a>
                  <a
                    href="/account"
                    className="nav_link /account"
                    id="/account"
                  >
                    <span className="nav_name">Account</span>
                  </a>
                  {isSuperAdmin ? (
                    <>
                      <a
                        href="/payments"
                        className="nav_link /account"
                        id="/payments"
                      >
                        <span className="nav_name">Payments</span>
                      </a>
                      <a
                        href="/settings"
                        className="nav_link /account"
                        id="/setting"
                      >
                        <span className="nav_name">Settings</span>
                      </a>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <button
                onClick={() => {
                  SignOutUser();
                }}
                className="signout_btn"
              >
                Sign Out
              </button>
            </nav>
          </div>
        </Col>
        <Col lg={10} md={10} sm={10}>
          <button className="d-block d-sm-none" onClick={() => OpenNavBar()}>
            MENU
          </button>
          <div className="Childrens">{children}</div>
        </Col>
      </Row>
    </div>
  );
};
export default SideNavBar;
