import React, { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import UpdatePricingTab from "../Components/settingTabs/updatePricingTab"
import AddPricingTab from "../Components/settingTabs/addPricingTab"
import "./styles/account.css";

function Settings({ authh }: any) {
  const role: Array<string> =
    authh.user?.signInUserSession?.accessToken?.payload["cognito:groups"];

  let isSuperAdmin = role && role.includes("superAdmin");
  let [currentTab, setCurrentTab] = useState("Add Pricing");
  
  if (authh.user && isSuperAdmin) {
    
      return (
        <div className="OrderPage-MainContainer">
          <h3>Settings</h3>
          <Row>
            <Col lg={1} md={1} sm={2} xs={2}>
              <button
                onClick={() => {
                  setCurrentTab("Add Pricing");
                }}
              >
                Add
              </button>
            </Col>
            <Col lg={1} md={1} sm={2} xs={2}>
              <button
                onClick={() => {
                  setCurrentTab("Update Pricing");
                }}
              >
                Update
              </button>
            </Col>
          </Row>
          {currentTab == "Update Pricing" ? <UpdatePricingTab/> : (
            <></>
          )}
          {currentTab == "Add Pricing" ? <AddPricingTab/> : (
            <></>
          )}
        </div>
      );
  } else {
    return (
      <div className="AccountPageDiv">
        <h3>Page Not Found</h3>
      </div>
    );
  }
}

export default Settings;
