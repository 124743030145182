import { FC } from "react";
import { Row, Col } from "react-bootstrap";
import { isDataView } from "util/types";

type PartnerDataType = {
  id: number;
  fullName: string;
  email: string;
  isVerified: boolean;
};

interface Props {
  data: PartnerDataType;
  view: string;
  role: Array<string>;
}

const SinglePartnerRow: FC<Props> = ({ data, view, role }) => {
  const isAdminOrSuperAdmin =
    role.includes("admin") || role.includes("superAdmin");
  const isSuperAdminOnly =
    role.includes("superAdmin") && !role.includes("admin");
  
  // Component for Web View  
  if (view === "web") {
    return (
      <>
        <Row className="orderRow" key={data.id}>
          <Col lg={3}>
            <p>{data.fullName}</p>
          </Col>
          <Col lg={3}>
            <p>{data.email}</p>
          </Col>
          <Col lg={3}>
            {data.isVerified === true ? <p>Verified</p> : <p>Not Verified</p>}
          </Col>
          <Col lg={3}>
            <a href={`/partners/partnerdetail/${data.id}`}>
              <button>View</button>
            </a>
            {!isSuperAdminOnly ? (
              <a href={`/partners/editpartner/${data.id}`}>
                <button>Edit</button>
              </a>
            ) : (
              <button className="disableBtn">Edit</button>
            )}
          </Col>
        </Row>
      </>
    );
  }
  
  // Component for Mobile View
  if (view === "mobile") {
    return (
      <>
        <Row className="orderRow" key={data.id}>
          <Col lg={3}>
            <p>Full Name: {data.fullName}</p>
          </Col>
          <Col lg={3}>
            <p>Email: {data.email}</p>
          </Col>
          <Col lg={3}>
            {data.isVerified === true ? (
              <p>Status: Verified</p>
            ) : (
              <p>Status: Not Verified</p>
            )}
          </Col>
          <Col lg={3} className="AlignBtnForSM">
            <a href={`/partners/partnerdetail/${data.id}`}>
              <button>View</button>
            </a>
            {!isSuperAdminOnly ? (
              <a href={`/partners/editpartner/${data.id}`}>
                <button>Edit</button>
              </a>
            ) : (
              <button className="disableBtn">Edit</button>
            )}
          </Col>
        </Row>
      </>
    );
  }
  return <></>;
};

export default SinglePartnerRow;
