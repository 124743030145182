export const moneyfy =
  (locale: string) =>
  (val: number): string => {
    const value = val || 0;

    return value.toLocaleString("en", {
      style: "currency",
      currency: locale,
    });
  };

export const nairalize = moneyfy("ngn");
