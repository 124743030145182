import { FC } from "react";

type AnalyticPropData = {
    name: String,
    value: number,
}

interface CardProps {
  data: AnalyticPropData;
};

const AnalyticsCard: FC<CardProps> = ({data}) => {
  return (
    <div className="SignleInsightbox">
      <div className="FontDiv">
        <b>$</b>
      </div>
      <div className="detailDiv">
        <b>{data.name}</b>
        <p>{data.value}</p>
      </div>
    </div>
  );
};
export default AnalyticsCard;
