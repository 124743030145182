import { useState, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { Row, Col } from "react-bootstrap";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_STATES,
  GET_MY_ORDERS_LIST,
  GET_CITIES_ON_BASIS_STATE,
  GET_SPECIFIC_CITI_STATES,
  GET_CUSTOMERS_LIST,
} from "../../graphql/quries";
import { ADD_NEW_PRICING } from "../../graphql/mutation";

function AddPricingTab() {
  const ALL_States = useQuery(GET_ALL_STATES);

  const [PickUpStateId, setPickUpStateId] = useState("");
  const [CustomerId, setCustomerId] = useState("");
  const [PickUpStateName, setPickUpStateName] = useState("");
  const [DesStateId, setDesStateId] = useState("");
  const [DesStateName, setDesStateName] = useState("");
  const [PickUpCityName, setPickUpCityName] = useState("");
  const [DesCityName, setDesCityName] = useState("");
  const [PriceWithOutVAT, setPriceWithOutVAT] = useState("");
  const [RouteWeight, setRouteWeight] = useState("");
  const [RouteMeasurement, setRouteMeasurement] = useState("");

  const [openLoader, setOpenLoader] = useState(false);
  const [openExistDailog, setOpenExistDailog] = useState(false);
  const Customers = useQuery(GET_CUSTOMERS_LIST);
  const [Add_New_Pricing] = useMutation(ADD_NEW_PRICING);
  const [getCities, ALL_Cities] = useLazyQuery(GET_CITIES_ON_BASIS_STATE, {
    variables: { _eq: parseInt(PickUpStateId) },
  });
  const [CheckData, Specific_Data] = useLazyQuery(GET_SPECIFIC_CITI_STATES, {
    variables: {
      customerId: CustomerId,
      destinationAreaOrCity: DesCityName,
      destinationState: DesStateName,
      measurementUnit: RouteMeasurement,
      originAreaOrCity: PickUpCityName,
      originState: PickUpStateName,
      priceWithoutVAT: PriceWithOutVAT,
      quantity: RouteWeight,
    },
  });
  const [getCitiesFromDes, ALL_CitiesDes] = useLazyQuery(
    GET_CITIES_ON_BASIS_STATE,
    {
      variables: { _eq: parseInt(DesStateId) },
    }
  );

  async function AddPrice(e: any) {
    e.preventDefault();

    CheckData();

    if (Specific_Data.data) {
      if (Specific_Data.data.pricing.length != 0) {
        handleClickOpen2();
      } else {
        try {
          handleToggleLoader();
          await Add_New_Pricing({
            variables: {
              customerId: CustomerId,
              destinationAreaOrCity: DesCityName,
              destinationState: DesStateName,
              measurementUnit: RouteMeasurement,
              originAreaOrCity: PickUpCityName,
              originState: PickUpStateName,
              quantity: parseInt(RouteWeight),
              priceWithoutVAT: PriceWithOutVAT,
            },
          });
        } catch (e) {}
        window.location.reload();
      }
    }

    handleCloseLoader();
  }

  function CustomePickUpCity(e: any) {
    e.preventDefault();
    let a = document.getElementById("PickUpCityID")!;
    let btn = document.getElementById("PickUpCityBtn")!;
    btn.style.display = "none";
    a.style.display = "none";
    let b = document.getElementById("PickUpFields")!;
    let inputPickUpField = document.createElement("input");
    inputPickUpField.placeholder = "Enter Customer City Name: ";
    inputPickUpField.addEventListener("change", (e: any) => {
      setPickUpCityName(e.target.value);
    });
    b.appendChild(inputPickUpField);
  }
  function CustomeDesCity(e: any) {
    e.preventDefault();
    let a = document.getElementById("DesCityID")!;
    let btn = document.getElementById("DesCityBtn")!;
    btn.style.display = "none";
    let b = document.getElementById("DesFields")!;
    let inputPickUpField = document.createElement("input");
    inputPickUpField.placeholder = "Enter Customer City Name: ";
    inputPickUpField.addEventListener("change", (e: any) => {
      setDesCityName(e.target.value);
    });
    a.style.display = "none";
    b.appendChild(inputPickUpField);
  }
  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleToggleLoader = () => {
    setOpenLoader(!openLoader);
  };

  const handleClickOpen2 = () => {
    setOpenExistDailog(true);
  };

  const handleClose2 = () => {
    setOpenExistDailog(false);
  };

  if (ALL_States.loading) {
    return <p>Loading...</p>;
  }
  if (Customers.data) {
    let Mycustomers = Customers.data.customer;
    console.log({ Mycustomers });
    return (
      <>
        <br />
        <h4>Add Pricing</h4>
        <br />
        <form
          onSubmit={(e) => {
            AddPrice(e);
          }}
        >
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              {Object.keys(Mycustomers).length === 0 ? (
                <></>
              ) : (
                <select
                  onChange={(e: any) => {
                    setCustomerId(e.target.value);
                  }}
                  className="SelectOptMenuForOrder"
                >
                  <option>Select Customer: </option>
                  {Mycustomers.map((customer: any, id: any) => {
                    const formatedCustomerType =
                      customer?.customerType?.toLowerCase();
                    const custId = customer?.id;
                    if (formatedCustomerType === "corporate") {
                      return (
                        <option key={id} value={customer.id}>
                          #{customer.id} {customer.companyName}
                        </option>
                      );
                    }
                    if (custId === 32) {
                      return (
                        <option key={id} value={customer.id}>
                          #{customer.id} {customer.companyName}
                        </option>
                      );
                    }
                  })}
                </select>
              )}
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <h6>
                <b>Select PickUp State</b>
              </h6>
              <select
                onChange={(e: any) => {
                  let val = JSON.parse(e.target.value);
                  setPickUpStateId(val.StateId);
                  setPickUpStateName(val.StateName);
                  getCities();
                }}
                className="SelectOptMenuForOrder"
              >
                <option>Select State</option>
                {ALL_States.data.State.map((State: any) => {
                  let StateWithSpace = State.name.replace(" State", "");
                  return (
                    <option
                      key={State.stateId}
                      value={JSON.stringify({
                        StateId: State.stateId,
                        StateName: StateWithSpace,
                      })}
                    >
                      {StateWithSpace}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <h6>
                <b>Select PickUp City</b>
                <button
                  id="PickUpCityBtn"
                  onClick={(e) => {
                    CustomePickUpCity(e!);
                  }}
                >
                  Add Custom
                </button>
              </h6>
              <div id="PickUpFields">
                <select
                  onChange={(e: any) => {
                    setPickUpCityName(e.target.value);
                  }}
                  id="PickUpCityID"
                  className="SelectOptMenuForOrder"
                >
                  <option>Select Cities</option>
                  {ALL_Cities.data != undefined ? (
                    <>
                      {ALL_Cities.data.AreaAndCity.map((City: any) => {
                        return (
                          <option key={City.cityId} value={City.name}>
                            {City.name}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <h6>
                <b>Enter Weight</b>
              </h6>
              <input
                onChange={(e: any) => {
                  setRouteWeight(e.target.value);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <h6>
                <b>Enter Measuring Unity</b>
              </h6>
              <input
                onChange={(e: any) => {
                  setRouteMeasurement(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <h6>
                <b>Select Destination State</b>
              </h6>
              <select
                onChange={(e: any) => {
                  let val = JSON.parse(e.target.value);
                  setDesStateId(val.StateId);
                  setDesStateName(val.StateName);
                  getCitiesFromDes();
                }}
                className="SelectOptMenuForOrder"
              >
                <option>Select State</option>
                {ALL_States.data.State.map((State: any) => {
                  let StateWithSpace = State.name.replace(" State", "");
                  return (
                    <option
                      key={State.stateId}
                      value={JSON.stringify({
                        StateId: State.stateId,
                        StateName: StateWithSpace,
                      })}
                    >
                      {StateWithSpace}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <h6>
                <b>Select Destination City</b>
                <button
                  id="DesCityBtn"
                  onClick={(e) => {
                    CustomeDesCity(e);
                  }}
                >
                  Add Custome
                </button>
              </h6>
              <div id="DesFields">
                <select
                  onChange={(e: any) => {
                    setDesCityName(e.target.value);
                  }}
                  id="DesCityID"
                  className="SelectOptMenuForOrder"
                >
                  <option>Select Cities</option>
                  {ALL_CitiesDes.data != undefined ? (
                    <>
                      {ALL_CitiesDes.data.AreaAndCity.map((City: any) => {
                        return (
                          <option key={City.cityId} value={City.name}>
                            {City.name}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <h6>
                <b>Add Price Without VAT</b>
              </h6>
              <input
                onChange={(e: any) => {
                  setPriceWithOutVAT(e.target.value);
                }}
              />
            </Col>
            <Col>
              <button type="submit">Add</button>
            </Col>
          </Row>
        </form>
        {/* Processing Loader */}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openLoader}
          onClick={handleCloseLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* Dialog for Creating new Truck */}
        <Dialog open={openExistDailog} onClose={handleClose2}>
          <div className="driverTruckPopup">
            <h4>That Route Already Exist !</h4>
            <button
              onClick={() => {
                handleClose2();
              }}
            >
              ok
            </button>
          </div>
        </Dialog>
      </>
    );
  } else {
    return <></>;
  }
}
export default AddPricingTab;
