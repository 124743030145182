import { gql } from "@apollo/client";

// CUSTOMERS QURIES
export const GET_CUSTOMERS_LIST = gql`
  query getMyCustomers {
    customer {
      lastName
      email
      id
      firstName
      customerType
      companyName
    }
  }
`;
export function GET_CUSTOMERS_DETAIL(id: any) {
  const GET_CD = gql`
    query getCustomers {
      customer(where: {id: {_eq: ${id}}}) {
        companyAddress
        companyName
        email
        firstName
        id
        insuranceEnabled
        lastName
        paymentMethod
        serviceChargeAmount
        serviceChargeEnabled
        rcType
        rcNum
        mobile
        verificationStatus
        surgeEnabled
        canPostOrders
        }
    }
  `;
  return GET_CD;
}

// ORDER QURIES
export const GET_MY_ORDERS_LIST = gql`
  query getMyOrder {
    order {
      id
      approvedByAdmin
      agreedPrice
      status
      partner {
        id
      }
    }
    customer {
      firstName
      companyName
      customerType
      id
    }
  }
`;
export const GET_DATA_FOR_POST = gql`
  query getMyOrder($cusId: Int!) {
    fetchPricingByCustomerId(object: { customerId: $cusId }) {
      pricingList {
        destinationAreaOrCity
        destinationState
        measurementUnit
        originAreaOrCity
        originState
        quantity
      }
    }
  }
`;

export function GET_SINGLE_ORDER_DETAIL(id: any) {
  const GET_SOD = gql`
  query getPartners {
    order(where: {id: {_eq: ${id}}}) {
        id
        deliveryAddress
        createdAt
        weight
        totalPayedAmount
        status
        secondPartPaymentAmount
        secondPartPayed
        firstPartPayed
        quantity
        price
        pickupPhoneNumber
        pickupFullname
        pickUpAddress
        pickupState
        pickedUpAt
        packageType
        itemType
        extraDetails
        dropOffInstruction
        deliveryState
        deliveryPhoneNumber
        deliveryFullname
        deliveryCode
        deliveredAt
        customerId
        agreedPrice
        driverId
        truckId
        partnerId
        customer {
          companyName
          firstName
          lastName
          customerType
        }
      }
      partner{
        fullName
        id
      }
      driver {
        id
        firstName
        lastName
      }
      truck {
        id
        chasisNumber
        plateNumber
      }
  }
  
`;
  return GET_SOD;
}

// PARTNERS QURIES

export const GET_PARTNERS_LIST = gql`
  query getPartners {
    partner {
      id
      fullName
      email
      isVerified
    }
  }
`;

export function GET_SINGLE_PARTNER(id: any){
   const GET_SP = gql`
    query MyQuery{
      partner(where: {id: {_eq: ${id}}}) {
        BVN
        driverLicense
        email
        fullName
        houseAddress
        id
        isVerified
        mobile
        temporaryPassowrd
        utilityBill
      }
    }
  `;
  return GET_SP
  
}

// PAYMENT QURIES

export function GET_PAYMENT_DETAIL(id: any) {
  const GET_PDL = gql`
  query getPartners {
    order(where: {id: {_eq: ${id}}}) {
        deliveryAddress
        createdAt
        weight
        totalPayedAmount
        status
        secondPartPaymentAmount
        secondPartPayed
        quantity
        price
        pickupPhoneNumber
        pickupFullname
        pickUpAddress
        pickedUpAt
        packageType
        itemType
        extraDetails
        dropOffInstruction
        deliveryState
        deliveryPhoneNumber
        deliveryFullname
        deliveryCode
        deliveredAt
        agreedPrice
        customerId
        firstPartPayed
        secondPartPayed
      }
  }
  
`;
  return GET_PDL;
}

// SUPER ADMIN QURIES

export const GET_APPROVED_ORDERS_LIST = gql`
  query getMyCustomers {
    order(where: { approvedByAdmin: { _eq: true } }) {
      id
      firstPartPayed
      secondPartPayed
      approvedByAdmin
    }
  }
`;

// DASHBOARD QURIES

export const GET_ANALYTICAL_INSIGTHS = gql`
  query getPartners {
    customer {
      id
    }
    order {
      id
      approvedByAdmin
    }
    partner {
      id
    }
  }
`;

// Setting Quries

export const GET_ALL_STATES = gql`
  query MyQuery {
    State {
      name
      stateId
    }
  }
`;

export const GET_CITIES_ON_BASIS_STATE = gql`
  query MyQuery($_eq: Int) {
    AreaAndCity(where: {stateId: {_eq: $_eq}}) {
      cityId
      name
      stateId
    }
  }
`;

export const GET_SPECIFIC_CITI_STATES = gql`
query MyQuery(
  $customerId: Int = 10
  $destinationAreaOrCity: String = ""
  $destinationState: String = ""
  $measurementUnit: String = ""
  $originAreaOrCity: String = ""
  $originState: String = ""
  $priceWithoutVAT: numeric = ""
  $quantity: numeric = ""
) {
  pricing(
    where: {
      customerId: { _eq: $customerId }
      destinationAreaOrCity: { _eq: $destinationAreaOrCity }
      destinationState: { _eq: $destinationState }
      measurementUnit: { _eq: $measurementUnit }
      originAreaOrCity: { _eq: $originAreaOrCity }
      originState: { _eq: $originState }
      priceWithoutVAT: { _eq: $priceWithoutVAT }
      quantity: { _eq: $quantity }
    }
  ) {
    priceWithoutVAT
  }
}

`;