import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Auth } from "aws-amplify";

// Importing Pages
import Login from "./Pages/login";
import Dashboard from "./Pages/dashboard";
import OrdersPage from "./Pages/orderspage";
import CustomersPage from "./Pages/customers";
import PartnerPage from "./Pages/partners";
import PartnerDetailPage from "./Pages/partnerdetail";
import PartnerEditPage from "./Pages/editpartnerpage";
import SideNavBar from "./Components/sidenavbar";
import DetailOrderPage from "./Pages/detailorder";
import EditOrderPage from "./Pages/editorder";
import CustomerDetailPage from "./Pages/customerdetail";
import AccountPage from "./Pages/account";
import SuperAdminPayments from "./Pages/superAdminPayments";
import PaymentDetailsPage from "./Pages/paymentdetailpage";
import Settings from "./Pages/setting";

function App() {
  
  let [IsAuthenticated, setIsAuthenticated] = useState(false);
  let [IsAuthenticating, setIsAuthenticating] = useState(true);
  let [user, setUser] = useState(null);

  let AuthenticationDetails = {
    IsAuthenticated,
    user,
    IsAuthenticating,
    setIsAuthenticated,
    setUser,
  };

  // Get Current Session if User is Already LoggedIn
  useEffect(() => {
    async function StartSession() {
      const UserSession = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
      setUser(user);
    }
    StartSession();
    setIsAuthenticating(false);
  }, []);
  
  
  return (
    <Router>
      {IsAuthenticated === false &&
      user === null &&
      IsAuthenticating === false ? (
        <Login authh={AuthenticationDetails} />
      ) : (
        <SideNavBar>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/orders"
              element={<OrdersPage authh={AuthenticationDetails} />}
            />
            <Route
              path="/orders/orderdetail/:id"
              element={<DetailOrderPage />}
            />
            <Route path="/orders/editorder/:id" element={<EditOrderPage />} />
            <Route path="/customers" element={<CustomersPage />} />
            <Route
              path="/customers/customerdetail/:id"
              element={<CustomerDetailPage />}
            />
            <Route
              path="/partners/"
              element={<PartnerPage authh={AuthenticationDetails} />}
            />
            <Route
              path="/partners/partnerdetail/:id"
              element={<PartnerDetailPage />}
            />
            <Route
              path="/partners/editpartner/:id"
              element={<PartnerEditPage />}
            />
            <Route
              path="/account"
              element={<AccountPage authh={AuthenticationDetails} />}
            />
            <Route
              path="/payments"
              element={<SuperAdminPayments authh={AuthenticationDetails} />}
            />
            <Route
              path="/payments/paymentdetails/:id"
              element={<PaymentDetailsPage authh={AuthenticationDetails} />}
            />
            <Route
              path="/settings"
              element={<Settings authh={AuthenticationDetails} />}
            />
          </Routes>
        </SideNavBar>
      )}
    </Router>
  );
}

export default App;
