import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./styles/login.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Amplify, { Auth } from "aws-amplify";

// Cognito Pool Credentials
Amplify.configure({
  Auth: {
    userPoolId: "us-east-1_otSyjRFrq", //UserPool ID
    region: "us-east-1",
    userPoolWebClientId: "3pmlvqkmal6905j7rn7ve5hr7n", //WebClientId
  },
});

function Login({ authh }: any) {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);
  
  // Methods to Open and Close Processing Loader
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  // Method on Login Btn pressed
  const handleChange = async (event: any) => {
    event.preventDefault();
    try {
      handleToggle();
      let user = await Auth.signIn(email, password);
      authh.setUser(user);
      authh.setIsAuthenticated(true);
    } catch (e) {
      handleClose();
    }
  };
  return (
    <div className="LoginMainContainer">
      <div className="loginform">
        <h3>Admin Login</h3>
        <form onSubmit={handleChange}>
          <Row>
            <Col></Col>
            <Col lg={5} md={10} sm={10}>
              <input
                className="LoginInp"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col lg={5} md={10} sm={10}>
              <input
                className="LoginInp"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Col>
            <Col></Col>
            <Col lg={12} md={12} sm={12}>
              <button className="LoginBtn" type="submit">
                Login
              </button>
            </Col>
          </Row>
        </form>
      </div>
      
      {/* Processing Loader */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Login;
