import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./styles/account.css";
import { Auth } from "aws-amplify";

function AccountPage({ authh }: any) {
  // User Can Change the Password
  let [currentPass, setCurrentPass] = useState("");
  let [newPass, setNewPass] = useState("");
  function changePassword() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPass, newPass);
      })
      .then((data) => {
        setCurrentPass("");
        setNewPass("");
      })
      .catch((err) => console.log(err));
  }
  if (authh.user !== null) {
    let UserEmail = authh.user.attributes.email;
    let UserNumber = authh.user.attributes.phone_number;
    let UserRole =
      authh.user.signInUserSession.accessToken.payload["cognito:groups"];

    return (
      <div className="AccountPageDiv">
        <h3>Account</h3>
        <Row className="DetailSec">
          <Col lg={12}>Email: {UserEmail}</Col>
          <Col lg={12}>Phone Number: {UserNumber}</Col>
          <Col lg={12}>
            Role(s):{" "}
            {UserRole.filter((role: string) => role !== "individual").join(
              ", "
            )}
          </Col>
        </Row>
        <Row className="UpdatePassSec">
          <Col lg={12}>
            <h4>Change Password</h4>
          </Col>
          <Col lg={12}>
            <input
              value={currentPass}
              onChange={(e) => {
                setCurrentPass(e.target.value);
              }}
              placeholder="Current Password"
            />
          </Col>
          <Col lg={12}>
            <input
              value={newPass}
              onChange={(e) => {
                setNewPass(e.target.value);
              }}
              placeholder="New Password"
            />
          </Col>
          <Col lg={12}>
            <button onClick={() => changePassword()}>Change</button>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="AccountPageDiv">
        <h3>Account</h3>
      </div>
    );
  }
}

export default AccountPage;
