import React, {useState, useRef} from "react";
import { Row, Col } from "react-bootstrap";
import "./styles/orderpage.css";
import { gql, useQuery, useMutation } from "@apollo/client";
import SinglePartnerRow from "../Components/partners/singlerPartnerRow";
import { SinglePartnerDataType } from "../Types/partners";
import ReactPaginate from "react-paginate";
import { GET_PARTNERS_LIST } from "../graphql/quries";
import { CREATE_NEW_PARTNER } from "../graphql/mutation";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
function PartnerPage({ authh }: any) {
  const { loading, error, data } = useQuery(GET_PARTNERS_LIST);
  const [pageNumber, setPageNumber] = React.useState(0);
  
  // New Partner Dailog States & Refs
  
  const partnerFullName = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerEmail = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerAddress = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerMobileNumber = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerTemporaryPassword = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerDrivingLicence = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerBVN = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerUtilityBill = useRef() as React.MutableRefObject<HTMLInputElement>;
  
  const [AddNewPartner] = useMutation(CREATE_NEW_PARTNER);
  const [openTruck, setOpenTruck] = useState(false);
  
  // Methods to Open & Close Dialogs
  const handleClickOpen2 = () => {
    setOpenTruck(true);
  };
  const handleClose2 = () => {
    setOpenTruck(false);
  };
  
  // Methods
  async function createPartner(e: any){
    e.preventDefault();
    await AddNewPartner({
      variables: {
        BVN: partnerBVN.current.value,
        driverLicense: partnerDrivingLicence.current.value,
        email: partnerEmail.current.value,
        fullName: partnerFullName.current.value,
        houseAddress: partnerAddress.current.value,
        mobile: partnerMobileNumber.current.value,
        temporaryPassowrd: partnerTemporaryPassword.current.value,
        utilityBill: partnerUtilityBill.current.value,
      },
    });
    window.location.reload();
    
  }
  if (loading) {
    return (
      <div className="OrderPage-MainContainer">
        <Row>
          <Col lg={6} md={6} sm={6} xs={12}>
            <h3>Partners</h3>
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <div className="NewOrderBtn_Div">
              <button className="orderBtn">
                Create New Partner
              </button>
            </div>
          </Col>
        </Row>
        <div className="OrderSection">
          <div className="OrderHeadings"></div>
          <div className="singleorder d-none d-sm-block">
            <Row className="orderHeadingRow">
              <Col lg={3} md={2}>
                <b>Name</b>
              </Col>
              <Col lg={3} md={2}>
                <b>Email</b>
              </Col>
              <Col lg={3} md={2}>
                <b>Is Verified</b>
              </Col>
              <Col lg={3} md={6}>
                <b>Actions</b>
              </Col>
            </Row>
            <Row className="LoadingComponent">
              <Col lg={12}>
                <h4>Loading....</h4>
              </Col>
            </Row>
          </div>
          <div className="OrdersPagination"></div>
        </div>
      </div>
    );
  }
  if (error || !data) {
    return <div>Error...</div>;
  }

  if (authh.user !== null) {
    
    let UserRole = authh.user.signInUserSession.accessToken.payload["cognito:groups"];
    const isAdminOrSuperAdmin =
    UserRole.includes("admin") || UserRole.includes("superAdmin");
    const isSuperAdminOnly =
    UserRole.includes("superAdmin") && !UserRole.includes("admin");
    
    // Code for Pagination
    let Partnerdata = data.partner;
    const PartnersPerPage = 20;
    const PageVisited = pageNumber * PartnersPerPage;
    const DisplayPaginatedPartnerWeb = Partnerdata.slice(
      PageVisited,
      PageVisited + PartnersPerPage
    ).map((partner: any) => {
      console.log(partner)
      return <SinglePartnerRow data={partner} view="web" role={UserRole} />;
    });
    const DisplayPaginatedPartnerMobile = Partnerdata.slice(
      PageVisited,
      PageVisited + PartnersPerPage
    ).map((partner: any) => {
      return <SinglePartnerRow data={partner} view="mobile" role={UserRole} />;
    });
  
    const PageCount = Math.ceil(Partnerdata.length / PartnersPerPage);
    const ChangePage = ({ selected }: any) => {
      setPageNumber(selected);
    };
    
    
    return (
      <div className="OrderPage-MainContainer">
        <Row>
          <Col lg={6} md={6} sm={6} xs={12}>
            <h3>Partners</h3>
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <div className="NewOrderBtn_Div">
            
              {!isSuperAdminOnly ? (
                <button className="orderBtn" onClick={handleClickOpen2}>
                  Create New Partner
                </button>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>
        <div className="OrderSection">
          <div className="OrderHeadings"></div>
          <div className="singleorder">
            <div className="d-none d-sm-block">
              <Row className="orderHeadingRow">
                <Col lg={3}>
                  <b>Name</b>
                </Col>
                <Col lg={3}>
                  <b>Email</b>
                </Col>
                <Col lg={3}>
                  <b>Is Verified</b>
                </Col>
                <Col lg={3}>
                  <b>Actions</b>
                </Col>
              </Row>
            </div>
            <div className="d-none d-sm-block">
              {DisplayPaginatedPartnerWeb}
            </div>
            <div className="d-sm-none d-md-block d-md-none d-lg-block d-lg-none d-xl-block d-xl-none">
              {DisplayPaginatedPartnerMobile}
            </div>
          </div>
          <div className="OrdersPagination">
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"next"}
              pageCount={PageCount}
              onPageChange={ChangePage}
              containerClassName={"paginationBtn"}
              previousLinkClassName={"paginationPrevBtn"}
              nextLinkClassName={"paginationNextBtn"}
              disabledClassName={"paginationDisabledBtn"}
              activeClassName={"paginationActiveBtn"}
            />
          </div>
        </div>
      <Dialog open={openTruck} onClose={handleClose2}>
          <div className="NewPartnerPop">
            <h4>Create New Partner</h4>
            <form onSubmit={(e)=>{ createPartner(e) }}>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <input placeholder="Full Name" ref={partnerFullName} />
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <input placeholder="Email" ref={partnerEmail}/>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <input placeholder="Address" ref={partnerAddress}/>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="Mobile Number" ref={partnerMobileNumber}/>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="Temporary Password" ref={partnerTemporaryPassword}/>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="Driving Licence" ref={partnerDrivingLicence}/>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="BVN" ref={partnerBVN}/>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="Utility Bill" ref={partnerUtilityBill}/>
                </Col>
                <Col lg={12} md={12} sm={12} className="driverTruckBtnAlignment">
                  <button>Create</button>
                </Col>
              </Row>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
  else{
    return <></>;
  }
  
}

export default PartnerPage;
