import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./styles/dashboard.css";
import { gql, useQuery } from "@apollo/client";
import TabOne from "../Components/dashboardTabs/tabOne";
import AnalyticsCard from "../Components/dashboard/analyticsCard";
import { GET_ANALYTICAL_INSIGTHS } from "../graphql/quries";

function Dashboard() {
  const { loading, error, data } = useQuery(GET_ANALYTICAL_INSIGTHS);
  let [currentTab, setCurrentTab] = useState("one");
  let addNewClass = document.getElementById(currentTab);
  if (addNewClass !== undefined) {
    addNewClass?.classList.add("activeSubTab");
  }
  if (loading) {
    return (
      <div>
        <h3>
          <b>Dashboard</b>
        </h3>
        <p>Loading ...</p>
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <h3>
          <b>Dashboard</b>
        </h3>
        <p>We Might Face Some Error ...</p>
      </div>
    );
  }
  let CountCustomers = data.customer;
  let CountOrders = data.order;
  let CountPartners = data.partner;
  let CountOrdersApprovedByAdmin = CountOrders.filter(function (el: any) {
    return el.approvedByAdmin === true;
  });
  return (
    <div>
      <h3>
        <b>Dashboard</b>
      </h3>
      <div className="analyticsInsigts">
        <Row>
          <Col lg={4} md={4} sm={6}>
            <AnalyticsCard
              data={{ name: "Orders", value: CountOrders.length }}
            />
          </Col>
          <Col lg={4} md={4} sm={6}>
            <AnalyticsCard
              data={{ name: "Customers", value: CountCustomers.length }}
            />
          </Col>
          <Col lg={4} md={4} sm={6}>
            <AnalyticsCard
              data={{ name: "Partners", value: CountPartners.length }}
            />
          </Col>
          <Col lg={4} md={4} sm={6}>
            <AnalyticsCard
              data={{
                name: "Approved",
                value: CountOrdersApprovedByAdmin.length,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={3} sm={6}>
            <button
              className="TabBtn"
              id="one"
              onClick={() => {
                let RemoveClass = document.getElementById(currentTab);
                RemoveClass?.classList.remove("activeSubTab");
                setCurrentTab("one");
              }}
            >
              Revenue
            </button>
          </Col>
        </Row>
        {currentTab === "one" ? <TabOne /> : <></>}
        <div className="HistoricDataLink">
          <b>
            To view historic data, please{" "}
            <a href="https://docs.google.com/spreadsheets/d/1_K6zRpybXNWowLZbtyTaEmad4KumsHalzUoumrADLOU/edit?usp=sharing">
              click here
            </a>
          </b>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
