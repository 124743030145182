import { FC } from "react";

type SubTabsPropData = {
  name: String;
  value: String;
  link: String;
};

interface Props {
  data: SubTabsPropData;
}

const SubTabsCard: FC<Props> = ({ data }) => {
  return (
    <>
      <h5>{data.name}</h5>
      <div className="SpaceArroundDiv">
        <p>{data.value}</p>
        <a href={`${data.link}`}>
          <button>View Details</button>
        </a>
      </div>
    </>
  );
};

export default SubTabsCard;
