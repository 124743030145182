import { FC } from "react";
import { Col } from "react-bootstrap";
import { nairalize } from "../../utils/moneyfy";

type UpdateApprovalType = (id: number) => void;
type OrderDataType = {
  agreedPrice: number;
  approvedByAdmin: boolean;
  id: number;
  status: string;
  partner: {
    id: number;
  };
  UpdateApproval: UpdateApprovalType;
};

interface Props {
  i: OrderDataType;
  view: string;
  role: Array<string>;
}
const SingleOrderRow: FC<Props> = ({ i, view, role }) => {
  const isAdminOrSuperAdmin =
    role.includes("admin") || role.includes("superAdmin");
  const isSuperAdminOnly =
    role.includes("superAdmin") && !role.includes("admin");
    
  // Component for Web View
  if (view === "web") {
    return (
      <>
        <Col lg={3} md={2}>
          <p>#{i.id}</p>
        </Col>
        {i.status !== "cancelled" ? (
          <>
            <Col lg={3} md={2}>
              <p>{nairalize(i.agreedPrice)}</p>
            </Col>
            {i.partner !== null ? (
              <Col lg={3} md={2}>
                {i.approvedByAdmin === true ? (
                  <div>
                    <p>Approved</p>
                  </div>
                ) : (
                  <div>
                    {isAdminOrSuperAdmin ? (
                      <button onClick={() => i.UpdateApproval(i.id)}>
                        Approve by Admin
                      </button>
                    ) : (
                      <>Partner Assigned but Not Approved</>
                    )}
                  </div>
                )}
              </Col>
            ) : (
              <Col lg={3} md={2}>
                {i.approvedByAdmin === true ? (
                  <div>
                    <p>Approved</p>
                  </div>
                ) : (
                  <div>
                    {isAdminOrSuperAdmin ? (
                      <button className="DisabledBtn">
                        Please Assign Partner First
                      </button>
                    ) : (
                      <>Pending</>
                    )}
                  </div>
                )}
              </Col>
            )}
          </>
        ) : (
          <Col lg={6} md={4}>
            <p>Order Cancelled</p>
          </Col>
        )}

        <Col lg={3} md={6}>
          <a href={`/orders/orderdetail/${i.id}`}>
            <button>View</button>
          </a>
          {i.status === "pending" && !isSuperAdminOnly ? (
            <a href={`/orders/editorder/${i.id}`}>
              <button>Edit</button>
            </a>
          ) : (
            <button className="disableBtn">Edit</button>
          )}
        </Col>
      </>
    );
  }
  
  // Component for Mobile View
  if (view === "mobile") {
    return (
      <>
        <Col lg={3}>
          <p>Order Id: #{i.id}</p>
        </Col>
        {i.status !== "cancelled" ? (
          <>
            <Col lg={3}>
              <p>Agreed Price: {i.agreedPrice}</p>
            </Col>
            {i.partner !== null ? (
              <Col lg={3}>
                {i.approvedByAdmin === true ? (
                  <div>
                    <p>Status: Approved</p>
                  </div>
                ) : (
                  <div>
                    {isAdminOrSuperAdmin ? (
                      <button onClick={() => i.UpdateApproval(i.id)}>
                        Approve by Admin
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </Col>
            ) : (
              <Col lg={3}>
                {i.approvedByAdmin === true ? (
                  <div>
                    <p>Status: Approved</p>
                  </div>
                ) : (
                  <div>
                    {isAdminOrSuperAdmin ? (
                      <button className="DisabledBtn">
                        Please Assign Partner First
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </Col>
            )}
          </>
        ) : (
          <Col lg={6} md={4}>
            <p>Status: Order Cancelled</p>
          </Col>
        )}

        <Col lg={3} className="AlignBtnForSM">
          <a href={`/orders/orderdetail/${i.id}`}>
            <button>View</button>
          </a>
          {i.status === "pending" && !isSuperAdminOnly ? (
            <a href={`/orders/editorder/${i.id}`}>
              <button>Edit</button>
            </a>
          ) : (
            <button className="disableBtn">Edit</button>
          )}
        </Col>
      </>
    );
  }
  return <></>;
};

export default SingleOrderRow;
