import * as React from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./styles/orderpage.css";
import Dialog from "@mui/material/Dialog";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import SingleOrderRow from "../Components/orders/singleOrderRow";
import { SingleOrderDataType } from "../Types/orders";
import ReactPaginate from "react-paginate";
import {
  GET_DATA_FOR_POST,
  GET_CUSTOMERS_LIST,
  GET_MY_ORDERS_LIST,
} from "../graphql/quries";
import {
  UPDATE_APPROVAL,
  CREATE_NEW_ORDER,
  GET_PRICE_WITHOUT_VAT,
} from "../graphql/mutation";
import { Auth } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { nairalize } from "../utils/moneyfy";

// Dialog Box for Creating New Order
function SimpleDialog(props: any) {
  let [customerID, setCustomerID] = React.useState(0);
  let [pickupState, setPickupState] = React.useState("");
  let [pickupAddress, setPickupAddress] = React.useState("");
  let [pickupWeight, setPickupWeight] = React.useState("");
  let [pickupWeightMeasurement, setPickupWeightMeasurement] =
    React.useState("");
  let [destinationState, setdestinationState] = React.useState("");
  let [destinationAddress, setdestinationAddress] = React.useState("");

  let [fullName, setFullName] = React.useState("");
  let [pickupPhoneNumb, setPickupPhoneNumb] = React.useState("");
  let [ItemDecsription, setItemDecsription] = React.useState("");
  let [DeliveryContactName, setDeliveryContactName] = React.useState("");
  let [DeliveryContactNumb, setDeliveryContactNumb] = React.useState("");
  let [DropOffInst, setDropOffInst] = React.useState("");
  let [itemType, setItemType] = React.useState("");
  let [packedType, setPackedType] = React.useState([""]);
  const [openLoader, setOpenLoader] = React.useState(false);
  let PriceWithoutVATValue;
  const [session, setSession] = React.useState<CognitoUserSession | null>(null);

  // Check the Current User Session
  React.useEffect(() => {
    const getSesstion = async () => {
      const userSession = await Auth.currentSession();
      setSession(userSession);
    };
    getSesstion();
  }, []);

  // Graphql Quries and Mutations
  const [getD, { data, loading, error }] = useLazyQuery(GET_DATA_FOR_POST, {
    variables: { cusId: customerID },
  });
  const [GetP, PriceWithoutVAT] = useLazyQuery(GET_PRICE_WITHOUT_VAT, {
    variables: {
      customerId: customerID,
      destinationAreaOrCity: destinationAddress,
      destinationState: destinationState,
      originAreaOrCity: pickupAddress,
      originState: pickupState,
      quantity: pickupWeight,
      measurementUnit: pickupWeightMeasurement,
    },
  });
  const [creatNewOrder] = useMutation(CREATE_NEW_ORDER, {
    context: {
      headers: {
        //@ts-ignore
        Authorization: `Bearer ${session?.idToken.jwtToken}`,
      },
    },
  });

  if (PriceWithoutVAT.data) {
    PriceWithoutVATValue = PriceWithoutVAT.data.pricing;
  }

  // Filteration of records that are Repeated many times
  let filterPickupState,
    FetchData: any,
    filteroriginAreaOrCity,
    filterPickupWeight,
    filterDestinationState;

  const uniqueValuesSetPS = new Set();
  const uniquDestinationState = new Set();
  const uniquDestinationAreaOrCity = new Set();
  const [filterDestinationAreaOrCity, setFilterDestinationAreaOrCity] =
    useState<Array<string> | undefined>();

  if (data !== undefined) {
    FetchData = data.fetchPricingByCustomerId.pricingList;
    filterPickupState = FetchData.filter((obj: any) => {
      const isPresentInSet = uniqueValuesSetPS.has(obj.originState);
      uniqueValuesSetPS.add(obj.originState);
      return !isPresentInSet;
    });
    filteroriginAreaOrCity = FetchData.filter((obj: any) => {
      const isPresentInSet = uniqueValuesSetPS.has(obj.originAreaOrCity);
      uniqueValuesSetPS.add(obj.originAreaOrCity);
      return !isPresentInSet;
    });
    filterPickupWeight = FetchData.filter((obj: any) => {
      const isPresentInSet = uniqueValuesSetPS.has(obj.quantity);
      uniqueValuesSetPS.add(obj.quantity);
      return !isPresentInSet;
    });
    FetchData.map((obj: any) => {
      uniquDestinationState.add(obj.destinationState);
    });
    FetchData.map((obj: any) => {
      uniquDestinationAreaOrCity.add(obj.destinationAreaOrCity);
    });

    filterDestinationState = Array.from(uniquDestinationState);
  }

  const { onClose, selectedValue, open, Mycustomers } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  function OpenHandleLoader() {
    setOpenLoader(true);
  }
  function CloseHandleLoader() {
    setOpenLoader(!openLoader);
  }

  function setCheckedValues(e: any) {
    if (e.target.checked === true) {
      setPackedType((oldone) => [...oldone, e.target.value]);
    }
  }
  async function submitButton() {
    try {
      OpenHandleLoader();
      await creatNewOrder({
        variables: {
          price: parseFloat(PriceWithoutVAT.data.pricing[0].priceWithoutVAT),
          weight: pickupWeight,
          customerId: `${customerID}`,
          deliveryAddress: destinationAddress,
          deliveryFullname: DeliveryContactName,
          deliveryPhoneNumber: DeliveryContactNumb,
          deliveryState: destinationState,
          description: ItemDecsription,
          dropOffInstruction: DropOffInst,
          image: "",
          itemType: itemType,
          packageType: `${packedType}`,
          pickUpAddress: pickupAddress,
          pickupFullname: fullName,
          pickupPhoneNumber: pickupPhoneNumb,
          pickupState: pickupState,
        },
      });
      window.location.reload();
    } catch (e) {
      CloseHandleLoader();
    }
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="CreateNewOrder_DialogDiv">
        <h4>Create New Order</h4>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {Object.keys(Mycustomers).length === 0 ? (
              <></>
            ) : (
              <select
                onChange={(e: any) => {
                  setCustomerID(parseInt(e.target.value));
                  getD();
                }}
                className="SelectOptMenuForOrder"
              >
                <option>Select Customer: </option>
                {Mycustomers.map((names: any, id: any) => {
                  if (names.customerType === "corporate") {
                    return (
                      <option key={id} value={names.id}>
                        #{names.id} {names.companyName}
                      </option>
                    );
                  }
                  if (names.customerType === "individual") {
                    return (
                      <option key={id} value={names.id}>
                        {`#${names.id} ${names.firstName}`}
                      </option>
                    );
                  }
                })}
              </select>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={6} xs={12}>
            <input
              onChange={(e: any) => setFullName(e.target.value)}
              placeholder="Full Name"
            />
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <input
              onChange={(e: any) => setPickupPhoneNumb(e.target.value)}
              placeholder="Pickup Phone Number"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={6} xs={12}>
            <select
              onChange={(e: any) => {
                setPickupState(e.target.value);
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Pickup State</option>
              {data !== undefined ? (
                <>
                  {filterPickupState !== undefined ? (
                    filterPickupState.map((a: any) => {
                      return (
                        <option key={a.originState} value={a.originState}>
                          {a.originState}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
          <Col lg={4} md={4} sm={6} xs={12}>
            <select
              onChange={(e: any) => {
                setPickupAddress(e.target.value);
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Pickup Address</option>
              {data !== undefined ? (
                <>
                  {filteroriginAreaOrCity !== undefined ? (
                    filteroriginAreaOrCity.map((a: any) => {
                      if (pickupState === a.originState) {
                        return (
                          <option
                            key={a.originAreaOrCity}
                            value={a.originAreaOrCity}
                          >
                            {a.originAreaOrCity}
                          </option>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
          <Col lg={4} md={4} sm={6} xs={12}>
            <select
              onChange={(e: any) => {
                let val = JSON.parse(e.target.value);
                setPickupWeight(val.w);
                setPickupWeightMeasurement(val.mu);
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Weight</option>
              {data !== undefined ? (
                <>
                  {filterPickupWeight !== undefined ? (
                    filterPickupWeight.map((a: any, i: number) => {
                      if (pickupAddress === a.originAreaOrCity) {
                        return (
                          <option
                            key={i}
                            value={JSON.stringify({
                              w: a.quantity,
                              mu: a.measurementUnit,
                            })}
                          >{`${a.quantity} ${a.measurementUnit}`}</option>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={6} xs={12}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Item type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                onChange={(e) => {
                  setItemType(e.target.value);
                }}
              >
                <FormControlLabel
                  value="Perishable"
                  control={<Radio />}
                  label="Perishable"
                />
                <FormControlLabel
                  value="fragile"
                  control={<Radio />}
                  label="fragile"
                />
              </RadioGroup>
            </FormControl>
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <h6>Package Type</h6>
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bagged"
              onChange={(e) => setCheckedValues(e)}
            />
            <label>Bagged</label>
            <input
              type="checkbox"
              id="vehicle2"
              name="vehicle2"
              value="Carton"
              onChange={(e) => setCheckedValues(e)}
            />
            <label>Carton</label>
            <input
              type="checkbox"
              id="vehicle3"
              name="vehicle3"
              value="Pieces"
              onChange={(e) => setCheckedValues(e)}
            />
            <label>Pieces</label>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={6} xs={12}>
            <input
              onChange={(e: any) => setItemDecsription(e.target.value)}
              placeholder="Enter Item Description"
            />
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <input
              onChange={(e: any) => setDeliveryContactName(e.target.value)}
              placeholder="Delivery Contact Name"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={6} xs={12}>
            <input
              onChange={(e: any) => setDeliveryContactNumb(e.target.value)}
              placeholder="Delivery Contact Number"
            />
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <input
              onChange={(e: any) => setDropOffInst(e.target.value)}
              placeholder="Drop Off Instruction"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={6} xs={12}>
            <select
              onChange={(e: any) => {
                setdestinationState(e.target.value);
                const cities = FetchData.filter(
                  (data: any) => data.destinationState === e.target.value
                ).map((state: any) => state.destinationAreaOrCity);
                setFilterDestinationAreaOrCity(cities);
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Delivery State</option>
              {data !== undefined ? (
                <>
                  {filterDestinationState !== undefined ? (
                    filterDestinationState.map((state: any) => {
                      return (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <select
              onChange={(e: any) => {
                setdestinationAddress(e.target.value);
                GetP();
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Delivery Address</option>
              {filterDestinationAreaOrCity?.map((areaOrCity: any) => (
                <option key={areaOrCity} value={areaOrCity}>
                  {areaOrCity}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        {PriceWithoutVAT.data ? (
          <p className="PriceStylingInCreateNO">
            <b>
              Price:{" "}
              {PriceWithoutVAT.data === undefined ||
              PriceWithoutVATValue.length === 0 ? (
                <>No Found</>
              ) : (
                nairalize(PriceWithoutVAT.data.pricing[0].priceWithoutVAT)
              )}
            </b>
          </p>
        ) : (
          <p className="PriceStylingInCreateNO"></p>
        )}
        <Row>
          <Col lg={3} md={4} sm={4}>
            <button className="CreateOrderBtn" onClick={() => submitButton()}>
              Order
            </button>
          </Col>
          <Col lg={2} md={2} sm={2}>
            {openLoader ? (
              <CircularProgress className="OrderLoader" color="warning" />
            ) : (
              <></>
            )}
          </Col>
          <Col lg={7} md={6} sm={6}></Col>
        </Row>
      </div>
    </Dialog>
  );
}

// Main Order Listing Section
function OrdersPage({ authh }: any) {
  const [open, setOpen] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [updateAp] = useMutation(UPDATE_APPROVAL);

  const { error, data } = useQuery(GET_CUSTOMERS_LIST);
  const { loading: ordersIsLoading, data: ordersList } =
    useQuery(GET_MY_ORDERS_LIST);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  async function UpdateApproval(id: any) {
    await updateAp({
      variables: {
        id: id,
      },
    });
    window.location.reload();
  }
  if (ordersIsLoading) {
    let EmptyMyCustomer = {};
    return (
      <div className="OrderPage-MainContainer">
        <Row>
          <Col lg={6} md={6} sm={6} xs={12}>
            <h3>Orders</h3>
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <div className="NewOrderBtn_Div">
              <button className="orderBtn" onClick={handleClickOpen}>
                Create New Order
              </button>
              <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                Mycustomers={EmptyMyCustomer}
              />
            </div>
          </Col>
        </Row>

        <div className="OrderSection">
          <div className="OrderHeadings"></div>
          <div className="singleorder d-none d-sm-block">
            <Row className="orderHeadingRow">
              <Col lg={3} md={2}>
                <b>Order Id</b>
              </Col>
              <Col lg={3} md={2}>
                <b>Agreed Price</b>
              </Col>
              <Col lg={3} md={2}>
                <b>Approve by Admin</b>
              </Col>
              <Col lg={3} md={6}>
                <b>Actions</b>
              </Col>
            </Row>

            <Row className="LoadingComponent">
              <Col lg={12}>
                <h4>Loading....</h4>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
  if (error || !ordersList) {
    return <div>Error...</div>;
  }

  let orders = ordersList.order;
  let SortedOrder = [...orders];
  SortedOrder.sort(function (x: any, y: any) {
    return y.id - x.id;
  });

  if (authh.user !== null) {
    let UserRole =
      authh.user.signInUserSession.accessToken.payload["cognito:groups"];

    // Code for Pagination
    const OrderPerPage = 20;
    const PageVisited = pageNumber * OrderPerPage;
    const DisplayPaginatedOrderWeb = SortedOrder.slice(
      PageVisited,
      PageVisited + OrderPerPage
    ).map((order: SingleOrderDataType) => {
      return (
        <Row key={order.id} className="orderRow">
          <SingleOrderRow
            i={{ ...order, ...UserRole, UpdateApproval }}
            view="web"
            role={UserRole}
          />
        </Row>
      );
    });
    const DisplayPaginatedOrderMobile = SortedOrder.slice(
      PageVisited,
      PageVisited + OrderPerPage
    ).map((order: SingleOrderDataType) => {
      return (
        <Row className="orderRow" key={order.id}>
          <SingleOrderRow
            i={{ ...order, ...UserRole, UpdateApproval }}
            view="mobile"
            role={UserRole}
          />
        </Row>
      );
    });
    const PageCount = Math.ceil(SortedOrder.length / OrderPerPage);
    const ChangePage = ({ selected }: any) => {
      setPageNumber(selected);
    };
    return (
      <div className="OrderPage-MainContainer">
        <Row>
          <Col lg={6} md={6} sm={6} xs={12}>
            <h3>Orders</h3>
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <div className="NewOrderBtn_Div">
              <button className="orderBtn" onClick={handleClickOpen}>
                Create New Order
              </button>
              <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                Mycustomers={data.customer}
              />
            </div>
          </Col>
        </Row>

        <div className="OrderSection">
          <div className="OrderHeadings"></div>
          <div className="singleorder">
            <div className="d-none d-sm-block">
              <Row className="orderHeadingRow">
                <Col lg={3} md={2}>
                  <b>Order Id</b>
                </Col>
                <Col lg={3} md={2}>
                  <b>Agreed Price</b>
                </Col>
                <Col lg={3} md={2}>
                  <b>Approve by Admin</b>
                </Col>
                <Col lg={3} md={6}>
                  <b>Actions</b>
                </Col>
              </Row>
            </div>
            <div>
              <div className="d-none d-sm-block">
                {DisplayPaginatedOrderWeb}
              </div>
              <div className="d-sm-none d-md-block d-md-none d-lg-block d-lg-none d-xl-block d-xl-none">
                {DisplayPaginatedOrderMobile}
              </div>
            </div>
          </div>
          <div className="OrdersPagination">
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"next"}
              pageCount={PageCount}
              onPageChange={ChangePage}
              containerClassName={"paginationBtn"}
              previousLinkClassName={"paginationPrevBtn"}
              nextLinkClassName={"paginationNextBtn"}
              disabledClassName={"paginationDisabledBtn"}
              activeClassName={"paginationActiveBtn"}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default OrdersPage;
