import { Row, Col } from "react-bootstrap";
import "./styles/orderpage.css";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { GET_SINGLE_ORDER_DETAIL, GET_MY_ORDERS_LIST, GET_DATA_FOR_POST } from "../graphql/quries";
import {
  UPDATE_ORDER,
  ADD_DRIVER,
  ADD_TRUCK,
  UPDATE_ORDER_STATUS,
  GET_PRICE_WITHOUT_VAT,
} from "../graphql/mutation";
import { nairalize } from "../utils/moneyfy";

function EditOrderPage() {
  let { id } = useParams();

  const [partnerId, setpartnerId] = useState("");
  const [driverID, setDriverID] = useState("");
  const [truckID, setTruckID] = useState("");
  const [AgreedPrice, setAgreedPrice] = useState(0);

  // DropDown Setting
  let PriceWithoutVATValue;
  let Mycustomers = [];
  const uniqueValuesSetPS = new Set();
  let [customerID, setCustomerID] = useState(0);
  let [pickupState, setPickupState] = useState("");
  let [pickupAddress, setPickupAddress] = useState("");
  let [pickupWeight, setPickupWeight] = useState("");
  let [pickupWeightMeasurement, setPickupWeightMeasurement] = useState("");
  let [destinationState, setdestinationState] = useState("");
  let [destinationAddress, setdestinationAddress] = useState("");
  let [ItemType, setItemType] = useState("");
  
  const [updateOrd] = useMutation(UPDATE_ORDER);
  const [AddNewDriver] = useMutation(ADD_DRIVER);
  const [AddNewTruck] = useMutation(ADD_TRUCK);
  const [UpdateStatus] = useMutation(UPDATE_ORDER_STATUS);

  const { loading, error, data } = useQuery(GET_SINGLE_ORDER_DETAIL(id));

  const [getD, DropDownCustomerData] = useLazyQuery(GET_DATA_FOR_POST, {
    variables: { cusId: customerID },
  });

  const [GetP, PriceWithoutVAT] = useLazyQuery(GET_PRICE_WITHOUT_VAT, {
    variables: {
      customerId: customerID,
      destinationAreaOrCity: destinationAddress,
      destinationState: destinationState,
      originAreaOrCity: pickupAddress,
      originState: pickupState,
      quantity: pickupWeight,
      measurementUnit: pickupWeightMeasurement,
    },
  });

  useEffect(() => {
    async function a() {
      let detailItem2 = await data.order[0];
      setpartnerId(detailItem2.partnerId);
      setDriverID(detailItem2.driverId);
      setTruckID(detailItem2.truckId);
      setCustomerID(detailItem2.customerId);
      setItemType(detailItem2.itemType)
      getD();
      setPickupState(detailItem2.pickupState);
      setPickupAddress(detailItem2.pickUpAddress);
      setPickupWeight(detailItem2.weight);
      setdestinationState(detailItem2.deliveryState);
      setdestinationAddress(detailItem2.deliveryAddress);
      setAgreedPrice(detailItem2.price);
      GetP();
    }
    if (data !== undefined) {
      a();
    }
  }, [data]);

  const PickUpFullName = useRef() as React.MutableRefObject<HTMLInputElement>;
  const PickUpPhoneNumber =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const PickUpState = useRef() as React.MutableRefObject<HTMLInputElement>;
  const PickUpAdress = useRef() as React.MutableRefObject<HTMLInputElement>;
  const PickUpWeight = useRef() as React.MutableRefObject<HTMLInputElement>;
  const ItemDescription = useRef() as React.MutableRefObject<HTMLInputElement>;
  const Update_DeliveryName =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const Update_DeliveryPhoneNumber =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const Update_DropOffInstruction =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const Update_AgreedPrice =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  // Create New Driver Truck Variables
  const driverFirstName = useRef() as React.MutableRefObject<HTMLInputElement>;
  const driverLastName = useRef() as React.MutableRefObject<HTMLInputElement>;
  const driverPhoneNumber =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const DriverLicenceNumber =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const truckPlateNumber = useRef() as React.MutableRefObject<HTMLInputElement>;
  const truckChasisNumber =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const truckColor = useRef() as React.MutableRefObject<HTMLInputElement>;
  const truckDescription = useRef() as React.MutableRefObject<HTMLInputElement>;
  const truckModel = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [openDriver, setOpenDriver] = useState(false);
  const [openTruck, setOpenTruck] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);

  const GET_ORDER_LIST = useQuery(GET_MY_ORDERS_LIST);
  if (GET_ORDER_LIST.data) {
    Mycustomers = GET_ORDER_LIST.data.customer;
  }

  if (PriceWithoutVAT.data) {
    PriceWithoutVATValue = PriceWithoutVAT.data.pricing;
  }

  // Filtration to get a single records of repeated ones.
  
  let filterPickupState,
    FetchData,
    filteroriginAreaOrCity,
    filterPickupWeight,
    filterdestinationState;

  if (DropDownCustomerData.data !== undefined) {
    FetchData = DropDownCustomerData.data.fetchPricingByCustomerId.pricingList;
    filterPickupState = FetchData.filter((obj: any) => {
      const isPresentInSet = uniqueValuesSetPS.has(obj.originState);
      uniqueValuesSetPS.add(obj.originState);
      return !isPresentInSet;
    });
    filteroriginAreaOrCity = FetchData.filter((obj: any) => {
      const isPresentInSet = uniqueValuesSetPS.has(obj.originAreaOrCity);
      uniqueValuesSetPS.add(obj.originAreaOrCity);
      return !isPresentInSet;
    });
    filterPickupWeight = FetchData.filter((obj: any) => {
      const isPresentInSet = uniqueValuesSetPS.has(obj.quantity);
      uniqueValuesSetPS.add(obj.quantity);
      return !isPresentInSet;
    });
    filterdestinationState = FetchData.filter((obj: any) => {
      const isPresentInSet = uniqueValuesSetPS.has(obj.destinationState);
      uniqueValuesSetPS.add(obj.destinationState);
      return !isPresentInSet;
    });
  }

  
  // Methods to Perform actions
  async function updateOrder() {
    console.log({ PriceWithoutVAT });
    await updateOrd({
      variables: {
        id: id,
        customerId: customerID,
        pickupFullname: PickUpFullName.current.value,
        pickupPhoneNumber: PickUpPhoneNumber.current.value,
        pickupState: pickupState,
        pickupAddress: pickupAddress,
        deliveryFullname: Update_DeliveryName.current.value,
        deliveryPhoneNumber: Update_DeliveryPhoneNumber.current.value,
        destinationState: destinationState,
        destinationAddress: destinationAddress,
        dropOffInstruction: Update_DropOffInstruction.current.value,
        agreedPrice: AgreedPrice,
        partnerId: partnerId,
        truckId: truckID,
        driverId: driverID,
        itemType: ItemType,
        price: PriceWithoutVAT?.data?.pricing[0]?.priceWithoutVAT || 0,
      },
    });
    window.history.back();
  }
  async function updateStatusSubmit() {
    try {
      handleToggleLoader();
      await UpdateStatus({
        variables: {
          id: id,
        },
      });
      window.location.reload();
    } catch (e) {}
    handleCloseLoader();
  }
  async function AddNewDriverSubmit(e: any) {
    e.preventDefault();
    await AddNewDriver({
      variables: {
        driverLicenseNumber: DriverLicenceNumber.current.value,
        driverFirstName: driverFirstName.current.value,
        driverLastName: driverLastName.current.value,
        driverPhoneNumber: driverPhoneNumber.current.value,
      },
    });
    window.location.reload();
  }

  async function AddNewTruckSubmit(e: any) {
    e.preventDefault();
    await AddNewTruck({
      variables: {
        truckChasisNumber: truckChasisNumber.current.value,
        truckDescription: truckDescription.current.value,
        truckColor: truckColor.current.value,
        truckPlateNumber: truckPlateNumber.current.value,
        truckModel: truckModel.current.value,
      },
    });
    window.location.reload();
  }

  // Methods of Open & Close the Dialogs
  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleToggleLoader = () => {
    setOpenLoader(!openLoader);
  };

  const handleClickOpen = () => {
    setOpenDriver(true);
  };

  const handleClose = () => {
    setOpenDriver(false);
  };
  const handleClickOpen2 = () => {
    setOpenTruck(true);
  };

  const handleClose2 = () => {
    setOpenTruck(false);
  };

  if (loading || DropDownCustomerData.loading) {
    return (
      <div className="DetailOrderPage-MainContainer">
        <h3>Order No: #{id}</h3>
        <div>Loading...</div>
      </div>
    );
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  
  // Get Details from Fetch Data
  let detailItem = data.order[0];
  let detailItem2 = data.order;
  let partnersName = data.partner;
  let driverData = data.driver;
  let truckData = data.truck;
  if (data && DropDownCustomerData.data) {
    return (
      <div className="DetailOrderPage-MainContainer">
        <Row>
          <Col lg={2} md={4} sm={12}>
            <h3>
              <b>Edit Order Id: {id}</b>
            </h3>
          </Col>
          <Col lg={6} md={4} sm={0}></Col>
          <Col lg={4} md={4} sm={12}>
            {detailItem.status === "pending" ? (
              <>
                <button onClick={() => updateStatusSubmit()}>
                  Cancle Order
                </button>
              </>
            ) : (
              <>
                <button className="disableBtn">Cancle Order</button>
              </>
            )}
          </Col>
        </Row>

        <Row className="OrderDetailRow">
          {Mycustomers !== undefined ? (
            <Col lg={12} md={12} sm={12} xs={12}>
              {Object.keys(Mycustomers).length === 0 ? (
                <></>
              ) : (
                <select
                  onChange={(e: any) => {
                    setCustomerID(parseInt(e.target.value));
                    getD();
                  }}
                  defaultValue={customerID}
                  className="SelectOptMenuForOrder"
                >
                  <option>Select Customer: </option>
                  {Mycustomers.map((names: any, id: any) => {
                    if (names.customerType === "corporate") {
                      return (
                        <option key={id} value={names.id}>
                          #{names.id} {names.companyName}
                        </option>
                      );
                    }
                    if (names.customerType === "individual") {
                      return (
                        <option key={id} value={names.id}>
                          {`#${names.id} ${names.firstName}`}
                        </option>
                      );
                    }
                  })}
                </select>
              )}
            </Col>
          ) : (
            <></>
          )}
          <Col lg={6} md={6} sm={12}>
            <b>PickUp FullName</b>
            <br />
            <input
              ref={PickUpFullName}
              defaultValue={detailItem.pickupFullname}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <b>Pickup Phone Number</b>
            <br />
            <input
              ref={PickUpPhoneNumber}
              defaultValue={detailItem.pickupPhoneNumber}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <select
              defaultValue={pickupState}
              onChange={(e: any) => {
                setPickupState(e.target.value);
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Pickup State</option>
              {DropDownCustomerData.data !== undefined ? (
                <>
                  {filterPickupState !== undefined ? (
                    filterPickupState.map((a: any) => {
                      return (
                        <option key={a.id} value={a.originState}>
                          {a.originState}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <select
              onChange={(e: any) => {
                setPickupAddress(e.target.value);
              }}
              className="SelectOptMenuForOrder"
              defaultValue={pickupAddress}
            >
              <option>Pickup Address</option>
              {data !== undefined ? (
                <>
                  {filteroriginAreaOrCity !== undefined ? (
                    filteroriginAreaOrCity.map((a: any) => {
                      if (pickupState === a.originState) {
                        return (
                          <option key={a.id} value={a.originAreaOrCity}>
                            {a.originAreaOrCity}
                          </option>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <select
              defaultValue={pickupWeight}
              onChange={(e: any) => {
                let val = JSON.parse(e.target.value);
                setPickupWeight(val.w);
                setPickupWeightMeasurement(val.mu);
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Weight</option>
              {data !== undefined ? (
                <>
                  {filterPickupWeight !== undefined ? (
                    filterPickupWeight.map((a: any) => {
                      if (pickupAddress === a.originAreaOrCity) {
                        return (
                          <option
                            key={a.id}
                            value={JSON.stringify({
                              w: a.quantity,
                              mu: a.measurementUnit,
                            })}
                          >{`${a.quantity} ${a.measurementUnit}`}</option>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Item type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={ItemType}
                onChange={(e)=>{setItemType(e.target.value)}}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="perishable"
                  control={<Radio />}
                  label="Perishable"
                />
                <FormControlLabel
                  value="fragile"
                  control={<Radio />}
                  label="fragile"
                />
              </RadioGroup>
            </FormControl>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <b>Item Description</b>
            <br />
            <input
              ref={ItemDescription}
              defaultValue={detailItem.extraDetails}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <b>Delivery Contact Name</b>
            <br />
            <input
              ref={Update_DeliveryName}
              defaultValue={detailItem.deliveryFullname}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <b>Delivery Contact Number</b>
            <br />
            <input
              ref={Update_DeliveryPhoneNumber}
              defaultValue={detailItem.deliveryPhoneNumber}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <b>Drop Off Instruction</b>
            <br />
            <input
              ref={Update_DropOffInstruction}
              defaultValue={detailItem.dropOffInstruction}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <select
              onChange={(e: any) => {
                setdestinationState(e.target.value);
              }}
              defaultValue={destinationState}
              className="SelectOptMenuForOrder"
            >
              <option>Delivery State</option>
              {data !== undefined ? (
                <>
                  {filterdestinationState !== undefined ? (
                    filterdestinationState.map((a: any) => {
                      return (
                        <option key={a.id} value={a.destinationState}>
                          {a.destinationState}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <select
              onChange={(e: any) => {
                setdestinationAddress(e.target.value);
                GetP();
              }}
              defaultValue={destinationAddress}
              className="SelectOptMenuForOrder"
            >
              <option>Delivery Address</option>
              {data !== undefined ? (
                <>
                  {filterdestinationState !== undefined ? (
                    filterdestinationState.map((a: any) => {
                      if (destinationState === a.destinationState) {
                        return (
                          <option key={a.id} value={a.destinationAreaOrCity}>
                            {a.destinationAreaOrCity}
                          </option>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
        </Row>
        {PriceWithoutVAT.data ? (
          <p className="PriceStylingInCreateNO">
            <b>
              Price:{" "}
              {PriceWithoutVAT.data === undefined ||
              PriceWithoutVATValue.length === 0 ? (
                <>No Found</>
              ) : (
                nairalize(PriceWithoutVAT.data.pricing[0].priceWithoutVAT)
              )}
            </b>
          </p>
        ) : (
          <p className="PriceStylingInCreateNO"></p>
        )}
        {detailItem.status === "pending" ? (
          <Row className="OrderDetailRow">
            <h3>
              <b>Assign Partner:</b>
            </h3>
            <Col lg={12} md={12} sm={12}>
              <select
                onChange={(e) => setpartnerId(e.target.value)}
                className="SelectOptMenu"
                defaultValue={detailItem.partnerId}
              >
                <option value="">Select Partner</option>
                {partnersName.map((a: any) => {
                  return (
                    <option key={a.id} value={a.id}>
                      {a.fullName}
                    </option>
                  );
                })}
              </select>
            </Col>

            <Col lg={12} md={12} sm={12}>
              <h3>
                <b>Assign Agreed Price:</b>
              </h3>
              <input
                type="number"
                onChange={(e) => setAgreedPrice(parseInt(e.target.value))}
                defaultValue={detailItem.agreedPrice}
                className="SetPrice"
                placeholder="Agreed Price"
              />
            </Col>
            <Col lg={4} md={12} sm={12}>
              <h4>
                <b>Assign Driver and Truck:</b>
              </h4>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <button onClick={handleClickOpen}>Add New Driver</button>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <button onClick={handleClickOpen2}>Add New Truck</button>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <select
                onChange={(e) => setDriverID(e.target.value)}
                className="SelectOptMenu"
                defaultValue={detailItem.driverId}
              >
                <option value="">Select Driver</option>
                {driverData.map((a: any) => {
                  return (
                    <option
                      key={a.id}
                      value={a.id}
                    >{`${a.firstName} ${a.lastName}`}</option>
                  );
                })}
              </select>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <select
                onChange={(e) => setTruckID(e.target.value)}
                className="SelectOptMenu"
                defaultValue={detailItem.truckId}
              >
                <option value="">Select Truck</option>
                {truckData.map((truck: any) => {
                  return (
                    <option
                      key={truck.id}
                      value={truck.id}
                    >{`Plate Number: ${truck.plateNumber}`}</option>
                  );
                })}
              </select>
            </Col>
            {partnerId !== null &&
            truckID !== null &&
            driverID !== null &&
            AgreedPrice !== null ? (
              <button
                onClick={() => {
                  handleToggleLoader();
                  updateOrder();
                }}
              >
                Save
              </button>
            ) : (
              <button disabled className="disableBtn">
                Save
              </button>
            )}
            
            {/* Processing Loader */}
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openLoader}
              onClick={handleCloseLoader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Row>
        ) : (
          <></>
        )}
        
        {/* Dialog for Creating new Driver */}
        <Dialog open={openDriver} onClose={handleClose}>
          <div className="driverTruckPopup">
            <h4>Add Driver</h4>
            <form onSubmit={(e) => AddNewDriverSubmit(e)}>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="First Name" ref={driverFirstName} />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="Last Name" ref={driverLastName} />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="Phone Number" ref={driverPhoneNumber} />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input
                    placeholder="Driver License Number"
                    ref={DriverLicenceNumber}
                  />
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className="driverTruckBtnAlignment"
                >
                  <button type="submit">Add</button>
                </Col>
              </Row>
            </form>
          </div>
        </Dialog>
        
        {/* Dialog for Creating new Truck */}
        <Dialog open={openTruck} onClose={handleClose2}>
          <div className="driverTruckPopup">
            <h4>Add Truck</h4>
            <form onSubmit={(e) => AddNewTruckSubmit(e)}>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="Plate Number" ref={truckPlateNumber} />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="Chasis Number" ref={truckChasisNumber} />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="Color" ref={truckColor} />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="Description" ref={truckDescription} />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <input placeholder="Truck Model" ref={truckModel} />
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className="driverTruckBtnAlignment"
                >
                  <button>Add</button>
                </Col>
              </Row>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
  return <></>;
}

export default EditOrderPage;
