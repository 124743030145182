import { Row, Col } from "react-bootstrap";
import "./styles/account.css";
import { gql, useQuery } from "@apollo/client";
import { GET_APPROVED_ORDERS_LIST } from "../graphql/quries";

function SuperAdminPayments({ authh }: any) {
  const { loading, error, data } = useQuery(GET_APPROVED_ORDERS_LIST);
  const role: Array<string> =
    authh.user?.signInUserSession?.accessToken?.payload["cognito:groups"];
  let isSuperAdmin = role && role.includes("superAdmin");

  if (authh.user && isSuperAdmin) {
    if (loading) {
      return (
        <div className="OrderPage-MainContainer">
          <h3>Payments</h3>
          <div className="OrderSection">
            <div className="OrderHeadings"></div>
            <div className="singleorder">
              <Row className="orderHeadingRow">
                <Col lg={3}>
                  <b>Order No:</b>
                </Col>
                <Col lg={3}>
                  <b>First Payment</b>
                </Col>
                <Col lg={3}>
                  <b>Second Payment</b>
                </Col>
                <Col lg={3}>
                  <b>Actions</b>
                </Col>
              </Row>
              <Row className="LoadingComponent">
                <Col lg={12}>
                  <h4>Loading....</h4>
                </Col>
              </Row>
            </div>
            <div className="OrdersPagination"></div>
          </div>
        </div>
      );
    }

    if (error || !data) {
      return <div>Error...</div>;
    }

    let ApprovedOrders = data.order;
    let SortedOrder = [...ApprovedOrders];
    SortedOrder.sort(function (x: any, y: any) {
      return y.id - x.id;
    });
    return (
      <div className="OrderPage-MainContainer">
        <h3>Payments</h3>
        <div className="OrderSection">
          <div className="OrderHeadings"></div>
          <div className="singleorder">
            <div className="d-none d-sm-block">
              <Row className="orderHeadingRow">
                <Col lg={3}>
                  <b>Order No:</b>
                </Col>
                <Col lg={3}>
                  <b>First Payment</b>
                </Col>
                <Col lg={3}>
                  <b>Second Payment</b>
                </Col>
                <Col lg={3}>
                  <b>Actions</b>
                </Col>
              </Row>
            </div>
            {SortedOrder.map((order: any, idx: number) => {
              return (
                <div key={idx}>
                  <div className="d-none d-sm-block">
                    <Row className="orderRow">
                      <Col lg={3}>
                        <p>#{order.id}</p>
                      </Col>
                      <Col lg={3}>
                        {order.firstPartPayed === false ? (
                          <p>Pending</p>
                        ) : (
                          <p>Paid</p>
                        )}
                      </Col>
                      <Col lg={3}>
                        {order.secondPartPayed === false ? (
                          <p>Pending</p>
                        ) : (
                          <p>Paid</p>
                        )}
                      </Col>
                      <Col lg={3}>
                        <a href={`/payments/paymentdetails/${order.id}`}>
                          <button>View</button>
                        </a>
                        <button>Edit</button>
                      </Col>
                    </Row>
                  </div>
                  <Row className="orderRow d-sm-none d-md-block d-md-none d-lg-block d-lg-none d-xl-block d-xl-none">
                    <Col lg={3}>
                      <p>Order Id: {order.id}</p>
                    </Col>
                    <Col lg={3}>
                      {order.firstPartPayed === false ? (
                        <p>First Part Status: Pending</p>
                      ) : (
                        <p>First Part Status: Paid</p>
                      )}
                    </Col>
                    <Col lg={3}>
                      {order.secondPartPayed === false ? (
                        <p>Secound Part Status: Pending</p>
                      ) : (
                        <p>Secound Part Status: Paid</p>
                      )}
                    </Col>
                    <Col lg={3} className="AlignBtnForSM">
                      <a href={`/payments/paymentdetails/${order.id}`}>
                        <button>View</button>
                      </a>
                      <button>Edit</button>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
          <div className="OrdersPagination"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="AccountPageDiv">
        <h3>Page Not Found</h3>
      </div>
    );
  }
}

export default SuperAdminPayments;
