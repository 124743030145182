import React from "react";
import { Row, Col } from "react-bootstrap";
import "./styles/orderpage.css";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_PAYMENT_DETAIL } from "../graphql/quries";
import { UPDATE_FP, UPDATE_SP } from "../graphql/mutation";
import { nairalize } from "../utils/moneyfy";

function PaymentDetailsPage({ authh }: any) {
  let { id } = useParams();
  const [updateFP] = useMutation(UPDATE_FP);
  const [updateSP] = useMutation(UPDATE_SP);

  const role: Array<string> =
    authh.user?.signInUserSession?.accessToken.payload["cognito:groups"];
  let isSuperAdmin = role && role.includes("superAdmin");

  const { loading, error, data } = useQuery(GET_PAYMENT_DETAIL(id));

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    throw Error(error?.message);
    return <div>Error...</div>;
  }

  // Methods
  async function updateFirstPayed() {
    await updateFP({
      variables: {
        id: id,
      },
    });
    window.history.back();
  }
  async function updateSecondPayed() {
    await updateSP({
      variables: {
        id: id,
      },
    });
    window.history.back();
  }

  let detailItem = data.order[0];
  if (authh.user !== null) {
    if (isSuperAdmin) {
      return (
        <div>
          <div className="DetailOrderPage-MainContainer">
            <h3>
              <b>Order Id: {id}</b>
            </h3>
            <Row className="OrderDetailRow">
              <Col lg={6} md={6} sm={12}>
                <b>Customer Id:</b>
                <p>#{detailItem.customerId}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Status</b>
                <p>#{detailItem.status}</p>
              </Col>
              <Col
                lg={12}
                md={12}
                sm={12}
                className="OrderDetailRow orderDetailRec"
              >
                <h4>
                  <b>Order Details</b>
                </h4>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Agreed Price:</b>
                <p>{nairalize(detailItem.agreedPrice)}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Weight:</b>
                <p>{detailItem.weight}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Price</b>
                <p>{nairalize(detailItem.price)}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Quantity</b>
                <p>{detailItem.quantity}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Package Type</b>
                <p>{detailItem.packageType}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Item Type</b>
                <p>{detailItem.itemType}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Paid Amount</b>
                <p>{detailItem.totalPayedAmount}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>First Part Amount</b>
                <p>{nairalize(detailItem.agreedPrice * 0.7)}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Secound Part Amount</b>
                <p>{nairalize(detailItem.agreedPrice * 0.3)}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>First Part Payment</b>
                <p>{nairalize(detailItem.secondPartPaymentAmount)}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Secound Part Payment</b>
                <p>{nairalize(detailItem.secondPartPaymentAmount)}</p>
              </Col>
              <Col
                lg={12}
                md={12}
                sm={12}
                className="OrderDetailRow orderDetailRec"
              >
                <h4>
                  <b>PickUp Details</b>
                </h4>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Pickup Full Name</b>
                <p>{detailItem.pickupFullname}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Pickup Phone Number</b>
                <p>{detailItem.pickupPhoneNumber}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Pickup Address</b>
                <p>{detailItem.pickUpAddress}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Pickup At</b>
                <p>{detailItem.pickedUpAt}</p>
              </Col>
              <Col
                lg={12}
                md={12}
                sm={12}
                className="OrderDetailRow orderDetailRec"
              >
                <h4>
                  <b>Delivery Details:</b>
                </h4>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Delivery Full Name</b>
                <p>{detailItem.deliveryFullname}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Delivery Phone Number</b>
                <p>{detailItem.deliveryPhoneNumber}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Delivery State</b>
                <p>{detailItem.deliveryState}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Delivery Address</b>
                <p>{detailItem.deliveryAddress}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Delivery Code</b>
                <p>{detailItem.deliveryCode}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Delivery At</b>
                <p>{detailItem.deliveredAt}</p>
              </Col>
              <Col
                lg={12}
                md={12}
                sm={12}
                className="OrderDetailRow orderDetailRec"
              >
                <h4>
                  <b>Extra:</b>
                </h4>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Drop Off Instruction</b>
                <p>{detailItem.dropOffInstruction}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Extra Details</b>
                <p>{detailItem.extraDetails}</p>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <b>Created At</b>
                <p>{detailItem.createdAt}</p>
              </Col>
            </Row>
          </div>
          <Row className="OrderDetailRow orderDetailRec">
            <Col lg={6}></Col>
            {detailItem.firstPartPayed === false ? (
              <Col lg={3}>
                <button onClick={() => updateFirstPayed()}>
                  Release 1st Payment
                </button>
              </Col>
            ) : (
              <Col lg={3}>
                <button>Paid</button>
              </Col>
            )}
            {detailItem.firstPartPayed === false ? (
              <Col lg={3}>
                <button className="DisabledBtn">Pending first payment</button>
              </Col>
            ) : (
              <Col lg={3}>
                {detailItem.secondPartPayed === false ? (
                  <button
                    onClick={() => {
                      updateSecondPayed();
                    }}
                  >
                    Release 2nd Payment
                  </button>
                ) : (
                  <button>Paid</button>
                )}
              </Col>
            )}
          </Row>
        </div>
      );
    } else {
      return (
        <div className="AccountPageDiv">
          <h3>Page Not Found</h3>
        </div>
      );
    }
  } else {
    return (
      <div className="AccountPageDiv">
        <h3>Page Not Found</h3>
      </div>
    );
  }
}

export default PaymentDetailsPage;
