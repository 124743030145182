import {useState , useRef, useEffect} from 'react';
import { Row, Col } from "react-bootstrap";
import "./styles/orderpage.css";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import {GET_SINGLE_PARTNER} from "../graphql/quries";
import { UPDATE_SINGLE_PARTNER } from "../graphql/mutation";

function DetailPartnerPage() {
  let { id } = useParams()
  
  const partnerFullName = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerEmail = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerAddress = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerMobileNumber = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerTemporaryPassword = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerDrivingLicence = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerBVN = useRef() as React.MutableRefObject<HTMLInputElement>;
  const partnerUtilityBill = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [partnerVerified, SetpartnerVerified] = useState("");
  const { loading, error, data } = useQuery(GET_SINGLE_PARTNER(id));
  
  const [AddNewPartner] = useMutation(UPDATE_SINGLE_PARTNER);
  
  // Set Default Values into Ref's
   useEffect(() => {
    async function a() {
      let Spartner2 = data.partner[0];
      partnerFullName.current.value = Spartner2.fullName;
      partnerEmail.current.value = Spartner2.email;
      partnerAddress.current.value = Spartner2.houseAddress;
      partnerMobileNumber.current.value = Spartner2.mobile;
      partnerDrivingLicence.current.value = Spartner2.driverLicense;
      partnerBVN.current.value = Spartner2.BVN;
      partnerUtilityBill.current.value = Spartner2.utilityBill;
      SetpartnerVerified(JSON.stringify(Spartner.isVerified))
    }
    if (data !== undefined) {
      a();
    }
  }, [data]);
  
  // Methods
  async function UpdatePartner(e: any){
    e.preventDefault();
    let a = (partnerVerified == "true");
    await AddNewPartner({
      variables: {
        _eq: id,
        BVN: partnerBVN.current.value,
        driverLicense: partnerDrivingLicence.current.value,
        email: partnerEmail.current.value,
        fullName: partnerFullName.current.value,
        houseAddress: partnerAddress.current.value,
        mobile: partnerMobileNumber.current.value,
        utilityBill: partnerUtilityBill.current.value,
        isVerified: (partnerVerified == "true"),
      },
    });
    
    window.location.reload();
  }
  
  
  if(loading)
    return <div className="DetailOrderPage-MainContainer">
      <h3> Partner Id: {id}</h3>
      <p> Loading... </p>
    </div>
  if(error)
    return <div className="DetailOrderPage-MainContainer">
      <h3> Partner Id: {id}</h3>
      <p> ERROR </p>
    </div>
  
  let Spartner = data.partner[0];
  return (
    <div className="DetailOrderPage-MainContainer">
      <h3>
        <b>Order Id: {id}</b>
      </h3>
      <form onSubmit={(e)=>UpdatePartner(e)}>
        <Row className="OrderDetailRow PartnerInputs">
          <Col lg={12} md={12} sm={12} className="OrderDetailRow orderDetailRec">
            <h4>
              <b>Partner Details</b>
            </h4>
          </Col>
          <Col lg={12} md={6} sm={12}>
            <b>First Name:</b><br/>
            <input ref={partnerFullName}/>
          </Col>
          <Col lg={12} md={6} sm={12}>
            <b>Email:</b>
            <input ref={partnerEmail} />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <b>House Address:</b>
            <input ref={partnerAddress}/>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <b>Mobile Number:</b>
            <input ref={partnerMobileNumber}/>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <b>Driver License:</b>
            <input ref={partnerDrivingLicence}/>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <b>BVN:</b>
            <input ref={partnerBVN}/>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <b>Is Verified:</b>
            { partnerVerified != ''? (
            <select defaultValue={`${partnerVerified}`} onChange={(e: any)=>{SetpartnerVerified(e.target.value)}}>
              <option value="true" >Verified</option>
              <option value="false" >Not Verified</option>
            </select>):<></>}
          </Col>
          <Col lg={6} md={6} sm={12}>
            <b>Utility Bill:</b>
            <input ref={partnerUtilityBill} />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <button className="FullWidthBtn" type="submit" >Update</button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default DetailPartnerPage;
