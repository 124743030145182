import { Row, Col } from "react-bootstrap";
import "../styles/subTabs.css";
import SubTabsCard from "./subTabsCard";

function TabOne() {
  return (
    <div className="SubTabContainer">
      <h3>
        <b>Revenue:</b>
      </h3>
      <Row>
        <Col lg={5} md={5} sm={12} className="TabOneBalanceSec">
          <SubTabsCard
            data={{ name: "Current Balance", value: "", link: "#" }}
          />
        </Col>
        <Col lg={5} md={5} sm={12} className="TabOneBalanceSec">
          <SubTabsCard
            data={{ name: "Payment Details", value: "", link: "#" }}
          />
        </Col>
      </Row>
    </div>
  );
}
export default TabOne;
