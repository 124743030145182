import { FC } from "react";
import { Row, Col } from "react-bootstrap";

type CustomerDataType = {
  lastName: string;
  email: string;
  id: number;
  firstName: string;
  customerType: string;
  companyName: string;
};

interface Props {
  data: CustomerDataType;
  view: string;
  currentCustomer: String;
}
const SingleCustomerRow: FC<any> = ({ data, view, currentCustomer }) => {

  // Component for Web View
  if (view === "web") {
    return (
      <>
        <Row className="orderRow" key={data.id}>
          {currentCustomer === "individual" ? (
            <>
              <Col lg={2}>
                <p>{data.firstName}</p>
              </Col>
              <Col>
                <p>{data.lastName}</p>
              </Col>
            </>
          ) : (
            <Col lg={4}>
              <p>{data.companyName}</p>
            </Col>
          )}
          <Col lg={4}>
            <p>{data.email}</p>
          </Col>
          <Col lg={3}>
            <a href={`/customers/customerdetail/${data.id}`}>
              <button>View</button>
            </a>
            <button>Edit</button>
          </Col>
        </Row>
      </>
    );
  }
  
  // Component for Mobile View
  if (view === "mobile") {
    return (
      <>
        <Row className="orderRow" key={data.id}>
          {currentCustomer === "individual" ? (
            <>
              <Col lg={2}>
                <p>FirstName: {data.firstName}</p>
              </Col>
              <Col>
                <p>LastName: {data.lastName}</p>
              </Col>
            </>
          ) : (
            <Col lg={4}>
              <p>Company: {data.companyName}</p>
            </Col>
          )}
          <Col lg={4}>
            <p>Email: {data.email}</p>
          </Col>
          <Col lg={3} className="AlignBtnForSM">
            <a href={`/customers/customerdetail/${data.id}`}>
              <button>View</button>
            </a>
            <button>Edit</button>
          </Col>
        </Row>
      </>
    );
  }
  return <></>;
};
export default SingleCustomerRow;
