import { Row, Col } from "react-bootstrap";
import "./styles/orderpage.css";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_SINGLE_PARTNER } from "../graphql/quries";

function DetailPartnerPage() {
  let { id } = useParams();

  const { loading, error, data } = useQuery(GET_SINGLE_PARTNER(id));

  if (loading)
    return (
      <div className="DetailOrderPage-MainContainer">
        <h3> Parnter Id: {id}</h3>
        <p> Loading... </p>
      </div>
    );
  if (error)
    return (
      <div className="DetailOrderPage-MainContainer">
        <h3> Partner Id: {id}</h3>
        <p> ERROR </p>
      </div>
    );
  let Spartner = data.partner[0];
  return (
    <div className="DetailOrderPage-MainContainer">
      <h3>
        <b>Parnter Id: {id}</b>
      </h3>
      <Row className="OrderDetailRow">
        <Col lg={12} md={12} sm={12} className="OrderDetailRow orderDetailRec">
          <h4>
            <b>Partner Details</b>
          </h4>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>First Name:</b>
          <p>{Spartner.fullName}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Email:</b>
          <p>{Spartner.email}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>House Address:</b>
          <p>{Spartner.houseAddress}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Mobile Number</b>
          <p>{Spartner.mobile}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Driver License</b>
          <p>{Spartner.driverLicense}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>BVN</b>
          <p>{Spartner.BVN}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>Is Verified</b>
          <p>{JSON.stringify(Spartner.isVerified)}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <b>utilityBill</b>
          <p>{Spartner.utilityBill}</p>
        </Col>
      </Row>
    </div>
  );
}

export default DetailPartnerPage;
