import { gql } from "@apollo/client";

// DETAIL ORDER SECTION MUTATION
export const UPDATE_PARTNER = gql`
  mutation MyMutation(
    $id: Int!
    $partnerID: Int!
    $AgreedValue: numeric!
    $driverId: Int!
    $truckId: Int!
  ) {
    update_order(
      where: { id: { _eq: $id } }
      _set: {
        partnerId: $partnerID
        agreedPrice: $AgreedValue
        driverId: $driverId
        truckId: $truckId
      }
    ) {
      returning {
        partnerId
      }
    }
  }
`;
export const ADD_DRIVER = gql`
  mutation MyMutation(
    $driverFirstName: String
    $driverLastName: String
    $driverPhoneNumber: String
    $driverLicenseNumber: String
  ) {
    insert_driver(
      objects: {
        driverLicenseNumber: $driverLicenseNumber
        firstName: $driverFirstName
        lastName: $driverLastName
        phoneNumber: $driverPhoneNumber
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export const ADD_TRUCK = gql`
  mutation MyMutation(
    $truckChasisNumber: String
    $truckColor: String
    $truckDescription: String
    $truckPlateNumber: String
    $truckModel: String
  ) {
    insert_truck(
      objects: {
        chasisNumber: $truckChasisNumber
        color: $truckColor
        description: $truckDescription
        plateNumber: $truckPlateNumber
        truckModel: $truckModel
      }
    ) {
      returning {
        id
      }
    }
  }
`;

// ORDER SECTION MUTATION

export const UPDATE_APPROVAL = gql`
  mutation MyMutation($id: Int!) {
    update_order(where: { id: { _eq: $id } }, _set: { approvedByAdmin: true }) {
      returning {
        approvedByAdmin
      }
    }
  }
`;



export const CREATE_NEW_ORDER = gql`
  mutation MyMutation(
    $price: Float!
    $quantity: String
    $weight: String
    $customerId: String
    $deliveryAddress: String!
    $deliveryFullname: String
    $deliveryPhoneNumber: String
    $deliveryState: String
    $description: String
    $dropOffInstruction: String
    $image: String!
    $itemType: String
    $packageType: String
    $pickUpAddress: String!
    $pickupFullname: String
    $pickupPhoneNumber: String
    $pickupState: String
  ) {
    createNewOrderAdmin(
      object: {
        deliveryAddress: $deliveryAddress
        image: $image
        pickUpAddress: $pickUpAddress
        priceWithVat: 1.5
        customerId: $customerId
        deliveryFullname: $deliveryFullname
        deliveryPhoneNumber: $deliveryPhoneNumber
        deliveryState: $deliveryState
        description: $description
        dropOffInstruction: $dropOffInstruction
        packageType: $packageType
        itemType: $itemType
        pickupFullname: $pickupFullname
        pickupPhoneNumber: $pickupPhoneNumber
        pickupState: $pickupState
        quantity: $quantity
        weight: $weight
        price: $price
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation MyMutation(
  $id: Int
  $customerId: Int
  $pickupFullname: String
  $pickupPhoneNumber: String
  $pickupState: String,
  $pickupAddress: String,
  $deliveryFullname: String
  $deliveryPhoneNumber: String
  $destinationState: String,
  $destinationAddress: String,
  $dropOffInstruction: String
  $agreedPrice: numeric
  $partnerId: Int
  $truckId: Int
  $itemType: String
  $driverId: Int
  $price: numeric!
) {
  update_order(
    where: { id: { _eq: $id } }
    _set: {
      customerId: $customerId
      pickupFullname: $pickupFullname
      pickupPhoneNumber: $pickupPhoneNumber
      pickupState: $pickupState
      pickUpAddress: $pickupAddress
      deliveryFullname: $deliveryFullname
      deliveryPhoneNumber: $deliveryPhoneNumber
      deliveryState: $destinationState
      deliveryAddress: $destinationAddress
      dropOffInstruction: $dropOffInstruction
      agreedPrice: $agreedPrice
      partnerId: $partnerId
      truckId: $truckId
      driverId: $driverId
      itemType: $itemType
      price: $price
    }
  ) {
    returning {
      id
    }
  }
}
`;
// PAYMENTDETAIL SECTION MUTATION

export const UPDATE_FP = gql`
  mutation MyMutation($id: Int!) {
    update_order(where: { id: { _eq: $id } }, _set: { firstPartPayed: true }) {
      returning {
        approvedByAdmin
      }
    }
  }
`;
export const UPDATE_SP = gql`
  mutation MyMutation($id: Int!) {
    update_order(where: { id: { _eq: $id } }, _set: { secondPartPayed: true }) {
      returning {
        approvedByAdmin
      }
    }
  }
`;

export const UPDATE_ORDER_STATUS = gql`
  mutation MyMutation($id: Int!) {
    update_order(where: {id: {_eq: $id}}, _set: {status: "cancelled"}) {
      returning {
        status
      }
    }
  }
`;
// GET PRICE WITHOUT VAT

export const GET_PRICE_WITHOUT_VAT = gql`
  query MyQuery(
    $customerId: Int
    $destinationAreaOrCity: String
    $destinationState: String
    $originAreaOrCity: String
    $originState: String
    $quantity: numeric
    $measurementUnit: String
  ) {
    pricing(
      where: {
        customerId: { _eq: $customerId }
        destinationAreaOrCity: { _eq: $destinationAreaOrCity }
        destinationState: { _eq: $destinationState }
        originAreaOrCity: { _eq: $originAreaOrCity }
        originState: { _eq: $originState }
        quantity: { _eq: $quantity }
        measurementUnit: { _eq: $measurementUnit }
      }
    ) {
      priceWithoutVAT
    }
  }
`;

export const UPDATE_PRICE_WITHOUT_VAT = gql`
  mutation MyMutation(
    $customerId: Int
    $destinationAreaOrCity: String
    $destinationState: String
    $originAreaOrCity: String
    $originState: String
    $quantity: numeric
    $priceWithoutVAT: numeric
  ) {
      update_pricing(
        where: {
          customerId: {_eq: $customerId}
          destinationAreaOrCity: { _eq: $destinationAreaOrCity }
          destinationState: { _eq: $destinationState }
          originAreaOrCity: { _eq: $originAreaOrCity }
          originState: { _eq: $originState }
          quantity: { _eq: $quantity }
        }
        _set: { priceWithoutVAT: $priceWithoutVAT }
      ) {
        returning {
          id
        }
      }
    }
  `;

// PARTNER SECTION MUTATIONS

export const CREATE_NEW_PARTNER = gql`
mutation MyMutation(
  $BVN: String 
  $driverLicense: String 
  $email: String
  $fullName: String
  $houseAddress: String
  $mobile: String
  $temporaryPassowrd: String
  $utilityBill: String
) {
  insert_partner_one(
    object: {
      BVN: $BVN
      driverLicense: $driverLicense
      email: $email
      fullName: $fullName
      houseAddress: $houseAddress
      mobile: $mobile
      temporaryPassowrd: $temporaryPassowrd
      utilityBill: $utilityBill
    }
  ) {
    id
  }
}
`;

export const UPDATE_SINGLE_PARTNER = gql`
  mutation MyMutation(
  $_eq: Int
  $email: String
  $driverLicense: String
  $BVN: String
  $fullName: String
  $houseAddress: String
  $isVerified: Boolean
  $mobile: String
  $utilityBill: String
) {
  update_partner(
    where: { id: { _eq: $_eq } }
    _set: {
      BVN: $BVN
      driverLicense: $driverLicense
      email: $email
      fullName: $fullName
      houseAddress: $houseAddress
      isVerified: $isVerified
      mobile: $mobile
      utilityBill: $utilityBill
    }
  ) {
    returning {
      id
    }
  }
}

`;

// Setting MUTATIONS

export const ADD_NEW_PRICING = gql`
mutation MyMutation(
  $customerId: Int 
  $destinationAreaOrCity: String
  $destinationState: String
  $measurementUnit: String
  $originAreaOrCity: String
  $originState: String
  $quantity: numeric!
  $priceWithoutVAT: numeric
) {
  insert_pricing_one(
    object: {
      customerId: $customerId
      destinationAreaOrCity: $destinationAreaOrCity
      destinationState: $destinationState
      measurementUnit: $measurementUnit
      originAreaOrCity: $originAreaOrCity
      originState: $originState
      quantity: $quantity
      priceWithoutVAT: $priceWithoutVAT
    }
  ) {
    id
  }
}
`;

// Customer Mutations

export const CreateNewCustomer = gql`
  mutation MyMutation(
    $companyAddress: String
    $companyName: String
    $customerType: String
    $email: String
    $firstName: String
    $lastName: String
    $rcType: String
    $rcNum: String
    $paymentMethod: String
    $mobile: String
){
  insert_customer_one(
    object: {
      companyAddress: $companyAddress
      companyName: $companyName
      customerType: $customerType
      email: $email
      firstName: $firstName
      lastName: $lastName
      rcType: $rcType
      rcNum: $rcNum
      paymentMethod: $paymentMethod
      mobile: $mobile
    }
  ) {
    id
  }
}
`;