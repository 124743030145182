import { Row, Col } from "react-bootstrap";
import { useState, useRef } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_DATA_FOR_POST, GET_CUSTOMERS_LIST } from "../../graphql/quries";
import {
  GET_PRICE_WITHOUT_VAT,
  UPDATE_PRICE_WITHOUT_VAT,
} from "../../graphql/mutation";
import { nairalize } from "../../utils/moneyfy";
import Dialog from "@mui/material/Dialog";

function UpdatePricingTab() {
  let [customerID, setCustomerID] = useState(0);
  let [pickupState, setPickupState] = useState("");
  let [pickupAddress, setPickupAddress] = useState("");
  let [pickupWeight, setPickupWeight] = useState("");
  let [pickupWeightMeasurement, setPickupWeightMeasurement] = useState("");
  let [destinationState, setdestinationState] = useState("");
  let [destinationAddress, setdestinationAddress] = useState("");
  let priceWOV = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [openTruck, setOpenTruck] = useState(false);
  let PriceWithoutVATValue;

  // Graphql Quries and Mutations
  const Customers = useQuery(GET_CUSTOMERS_LIST);
  const [UpdatePRICE_VOV] = useMutation(UPDATE_PRICE_WITHOUT_VAT);
  const [getD, { data, loading, error }] = useLazyQuery(GET_DATA_FOR_POST, {
    variables: { cusId: customerID },
  });
  const [GetP, PriceWithoutVAT] = useLazyQuery(GET_PRICE_WITHOUT_VAT, {
    variables: {
      customerId: customerID,
      destinationAreaOrCity: destinationAddress,
      destinationState: destinationState,
      originAreaOrCity: pickupAddress,
      originState: pickupState,
      quantity: pickupWeight,
      measurementUnit: pickupWeightMeasurement,
    },
  });

  // Filteration of records that are Repeated many times
  let filterPickupState,
    FetchData: any,
    filteroriginAreaOrCity,
    filterPickupWeight,
    filterDestinationState;

  const uniqueValuesSetPS = new Set();
  const uniquDestinationState = new Set();
  const uniquDestinationAreaOrCity = new Set();
  const [filterDestinationAreaOrCity, setFilterDestinationAreaOrCity] =
    useState<Array<string> | undefined>();

  if (data !== undefined) {
    FetchData = data.fetchPricingByCustomerId.pricingList;
    filterPickupState = FetchData.filter((obj: any) => {
      const isPresentInSet = uniqueValuesSetPS.has(obj.originState);
      uniqueValuesSetPS.add(obj.originState);
      return !isPresentInSet;
    });
    filteroriginAreaOrCity = FetchData.filter((obj: any) => {
      const isPresentInSet = uniqueValuesSetPS.has(obj.originAreaOrCity);
      uniqueValuesSetPS.add(obj.originAreaOrCity);
      return !isPresentInSet;
    });
    filterPickupWeight = FetchData.filter((obj: any) => {
      const isPresentInSet = uniqueValuesSetPS.has(obj.quantity);
      uniqueValuesSetPS.add(obj.quantity);
      return !isPresentInSet;
    });
    FetchData.map((obj: any) => {
      uniquDestinationState.add(obj.destinationState);
    });
    FetchData.map((obj: any) => {
      uniquDestinationAreaOrCity.add(obj.destinationAreaOrCity);
    });

    filterDestinationState = Array.from(uniquDestinationState);
  }

  async function updatePrice(e: any) {
    e.preventDefault();
    await UpdatePRICE_VOV({
      variables: {
        customerId: customerID,
        destinationAreaOrCity: destinationAddress,
        destinationState: destinationState,
        originAreaOrCity: pickupAddress,
        originState: pickupState,
        quantity: pickupWeight,
        priceWithoutVAT: parseInt(priceWOV.current.value),
      },
    });
    GetP();
    handleClose2();
  }

  // Methods to Open & Close Dialogs
  const handleClickOpen2 = () => {
    setOpenTruck(true);
  };
  const handleClose2 = () => {
    setOpenTruck(false);
  };
  if (PriceWithoutVAT.data) {
    PriceWithoutVATValue = PriceWithoutVAT.data.pricing;
  }

  if (Customers.data) {
    let Mycustomers = Customers.data.customer;
    return (
      <>
        <br />
        <h4>Update Pricing:</h4>
        <br />
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {Object.keys(Mycustomers).length === 0 ? (
              <></>
            ) : (
              <select
                onChange={(e: any) => {
                  setCustomerID(parseInt(e.target.value));
                  getD();
                }}
                className="SelectOptMenuForOrder"
              >
                <option>Select Customer: </option>
                {Mycustomers.map((customer: any, id: any) => {
                  const formatedCustomerType =
                    customer?.customerType?.toLowerCase();
                  const custId = customer?.id;
                  if (formatedCustomerType === "corporate") {
                    return (
                      <option key={id} value={customer.id}>
                        #{customer.id} {customer.companyName}
                      </option>
                    );
                  }
                  if (custId === 32) {
                    return (
                      <option key={id} value={customer.id}>
                        #{customer.id} {customer.companyName}
                      </option>
                    );
                  }
                })}
              </select>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={6} xs={12}>
            <select
              onChange={(e: any) => {
                setPickupState(e.target.value);
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Pickup State</option>
              {data !== undefined ? (
                <>
                  {filterPickupState !== undefined ? (
                    filterPickupState.map((a: any) => {
                      return (
                        <option key={a.originState} value={a.originState}>
                          {a.originState}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
          <Col lg={4} md={4} sm={6} xs={12}>
            <select
              onChange={(e: any) => {
                setPickupAddress(e.target.value);
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Pickup Address</option>
              {data !== undefined ? (
                <>
                  {filteroriginAreaOrCity !== undefined ? (
                    filteroriginAreaOrCity.map((a: any) => {
                      if (pickupState === a.originState) {
                        return (
                          <option
                            key={a.originAreaOrCity}
                            value={a.originAreaOrCity}
                          >
                            {a.originAreaOrCity}
                          </option>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
          <Col lg={4} md={4} sm={6} xs={12}>
            <select
              onChange={(e: any) => {
                let val = JSON.parse(e.target.value);
                setPickupWeight(val.w);
                setPickupWeightMeasurement(val.mu);
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Weight</option>
              {data !== undefined ? (
                <>
                  {filterPickupWeight !== undefined ? (
                    filterPickupWeight.map((a: any, idx: number) => {
                      if (pickupAddress === a.originAreaOrCity) {
                        return (
                          <option
                            key={idx}
                            value={JSON.stringify({
                              w: a.quantity,
                              mu: a.measurementUnit,
                            })}
                          >{`${a.quantity} ${a.measurementUnit}`}</option>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={6} xs={12}>
            <select
              onChange={(e: any) => {
                setdestinationState(e.target.value);
                const cities = FetchData.filter(
                  (data: any) => data.destinationState === e.target.value
                ).map((state: any) => state.destinationAreaOrCity);
                setFilterDestinationAreaOrCity(cities);
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Delivery State</option>
              {data !== undefined ? (
                <>
                  {filterDestinationState !== undefined ? (
                    filterDestinationState.map((state: any) => {
                      return (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </select>
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <select
              onChange={(e: any) => {
                setdestinationAddress(e.target.value);
                GetP();
              }}
              className="SelectOptMenuForOrder"
            >
              <option>Delivery Address</option>
              {filterDestinationAreaOrCity?.map((areaOrCity: any) => (
                <option key={areaOrCity} value={areaOrCity}>
                  {areaOrCity}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        {PriceWithoutVAT.data ? (
          <p className="PriceStylingInCreateNO">
            <button id="Pupdate1" onClick={handleClickOpen2}>
              Change
            </button>
            <b>
              Price:{" "}
              {PriceWithoutVAT.data === undefined ||
              PriceWithoutVATValue.length === 0 ? (
                <>No Found</>
              ) : (
                nairalize(PriceWithoutVAT.data.pricing[0].priceWithoutVAT)
              )}
            </b>
          </p>
        ) : (
          <p className="PriceStylingInCreateNO"></p>
        )}
        <Dialog open={openTruck} onClose={handleClose2}>
          <div className="NewPartnerPop">
            <h4>Change Price</h4>
            <form>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  {PriceWithoutVAT.data === undefined ||
                  PriceWithoutVATValue.length === 0 ? (
                    <input
                      placeholder="Price"
                      ref={priceWOV}
                      defaultValue="0"
                    />
                  ) : (
                    <input
                      placeholder="Price"
                      ref={priceWOV}
                      defaultValue={
                        PriceWithoutVAT.data.pricing[0].priceWithoutVAT
                      }
                    />
                  )}
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className="driverTruckBtnAlignment"
                >
                  <button
                    onClick={(e) => {
                      updatePrice(e);
                    }}
                  >
                    Change
                  </button>
                </Col>
              </Row>
            </form>
          </div>
        </Dialog>
      </>
    );
  } else {
    return <></>;
  }
}
export default UpdatePricingTab;
